import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";

export class specialnumberslist extends Component {
    static displayName = specialnumberslist.name;

    constructor(props) {
        super(props);
        this.state = { specialnumberslist: [], loading: true, selectedrec: "" };
    }

    componentDidMount() {
        this.populateSampleRequests();
    }
 

    renderSampleRequestsTable(specialnumberslist, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
 <th className="fontSmall fontBold">S.No</th>
                        <th className="fontSmall fontBold">Number</th>
                        <th className="fontSmall fontBold">Created On</th>
                     
                    </tr>
                </thead>
                <tbody>
                    {this.state.specialnumberslist.length > 0 ? this.state.specialnumberslist.map((x,idx) =>(
                        <tr key={x.id}>
                            <td> {idx + 1}</td>
                            <td>
                                {x.number}
                            </td>
                            <td>
                                {x.created_datetime}
                            </td>
                           
                        </tr>
                    )) : <tr><td colspan="2"><div className="text-center fontBold"> No Special Numbers are uploaded yet.</div></td></tr>}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateSampleRequests();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderSampleRequestsTable(this.state.specialnumberslist, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Special Numbers List</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Numbers</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Special Numbers</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 tabCustom mb-5">
                    <div className="container  px-0 tab-pane active">
                        <div className={this.state.Message ? "alert alert-danger alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                            <strong>
                                {this.state.Message}
                            </strong>
                        </div>
                        <div className="card custom-card">
                            <div className="card-body">
                                {contents}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateSampleRequests() {
        debugger;
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/specialnumbers', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ specialnumberslist: data.data, loading: false });
        }
    }
}

export default specialnumberslist;

