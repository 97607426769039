import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import logo from '../gdp/root/images/logo/logo.png';

export class UserRegistration extends Component {
    static displayName = UserRegistration.name;
    constructor(props) {
        super(props);
        this.state = { Name: "", Phone: "", Email: "", Password: "", ConfirmPassword: "", Message: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    componentDidMount() {
        console.log('s');
        $('body').attr('class', 'page login-img');
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    validate() {
        let messageError = "";
        if (!this.state.Name) {
            messageError = "Name is required";
        }
        if (!this.state.Phone) {
            messageError = "Phone is required";
        }
        if (!this.state.Email) {
            messageError = "Email is required";
        }
        if (!this.state.Password) {
            messageError = "Password is required";
        }
        if (!this.state.ConfirmPassword) {
            messageError = "ConfirmPassword is required";
        }
        if (!(!this.state.Password && !this.state.ConfirmPassword)) {
            if (this.state.Password != this.state.ConfirmPassword)
                messageError = "Password doesn't match";
        }
        if (messageError) {
            this.setState({ Message: messageError });
            return false;
        }
        return true;
    }
    handleSubmit = (e) => {
        debugger;
        e.preventDefault();
        if (this.validate()) {
            try {
                fetch('/userregistrationapi/post', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: "POST",
                    body: JSON.stringify({
                        Name: this.state.Name,
                        Phone: this.state.Phone,
                        Email: this.state.Email,
                        Password: this.state.Password,
                        ConfirmPassword: this.state.ConfirmPassword
                    })
                }).then((response) => {
                    return response.json();
                })
                    .then((jsonData) => {
                        console.log(jsonData);
                        if (jsonData.isSuccess) {
                            Array.from(document.querySelectorAll("input")).forEach(
                                input => (input.value = "")
                            );
                            this.setState({ Message: jsonData.message });
                            setTimeout(() => {
                                this.props.history.push("/login");
                            }, 2000);
                            //ReactSession.setStoreType("localStorage");
                            //ReactSession.set("login_datetime", new Date());
                            //ReactSession.set("user_id", jsonData.data.id);
                            //ReactSession.set("username", jsonData.data.username);
                            //ReactSession.set("session_user", jsonData.data);
                            //this.props.history.push("/login");

                        }
                        else {
                            this.setState({ Message: jsonData.message });
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }
    }
    render() {
        return (
            <div>
                <div className="container-login100 col-12">
                    <div className="wrap-login100 p-0">
                        <div className="card-body">
                            <form className="login100-form validate-form" onSubmit={this.handleSubmit}>
                                <div className={this.state.Message ? "alert alert-success alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                                    <strong>
                                        {this.state.Message}
                                    </strong>
                                </div>
                                <div className="text-center">
                                    <img src={logo} alt="logo image" className="img-responsive mx-auto" width="150" />
                                </div>
                                <span className="mt-4 mb-4 text-left d-block"> Please enter the required details to register </span>
                                <div className="wrap-input100 validate-input inputs">
                                    <input autoComplete="off" className="input100" type="text" name="Name" id="Name" onChange={this.handleInputChange} placeholder=" " /><label>Enter Name</label>
                                    <span className="symbol-input100"> <i className="fa  fa-user" aria-hidden="true"></i> </span>
                                </div>
                                <div className="wrap-input100 validate-input inputs mt-3">
                                    <input autoComplete="off" className="input100" type="text" name="Phone" id="Phone" onChange={this.handleInputChange} placeholder=" " /><label>Enter Phone Number</label>
                                    <span className="symbol-input100"> <i className="zmdi zmdi-phone" aria-hidden="true"></i> </span>
                                </div>
                                <div className="wrap-input100 validate-input inputs mt-3">
                                    <input autoComplete="off" className="input100" type="text" name="Email" id="Email" onChange={this.handleInputChange} placeholder=" " /><label>Enter Email ID</label>
                                    <span className="symbol-input100"> <i className="zmdi zmdi-email" aria-hidden="true"></i> </span>
                                </div>
                                <div className="wrap-input100 validate-input inputs mt-3">
                                    <input autoComplete="off" className="input100" type="password" name="Password" id="Password" onChange={this.handleInputChange} placeholder=" " /><label>Enter Password</label> <span className="focus-input100"></span> <span className="symbol-input100"> <i className="zmdi zmdi-lock" aria-hidden="true"></i> </span>
                                </div>
                                <div className="wrap-input100 validate-input inputs mt-3">
                                    <input autoComplete="off" className="input100" type="password" name="ConfirmPassword" id="ConfirmPassword" onChange={this.handleInputChange} placeholder=" " /> <label>Re-Enter Password</label> <span className="focus-input100"></span> <span className="symbol-input100"> <i className="zmdi zmdi-lock" aria-hidden="true"></i> </span>
                                </div>
                                <div className="container-login100-form-btn">
                                    <button type="submit" className="login100-form-btn btn-success"> Submit </button>

                                </div>
                            </form>

                        </div>

                    </div>
                    <NavLink className="text-center col-12 text-white pt-2" tag={Link} to="/login">
                        Back to <span className="h4">Login</span>
                    </NavLink>
                    {/*<a href="registration.html" className="text-center col-12 text-white pt-2">*/}
                    {/*    Registration for <span className="h4">Seller</span>*/}
                    {/*</a>*/}
                </div>
            </div>
        );
    }
}

export default UserRegistration;