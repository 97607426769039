import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import backend from "i18next-xhr-backend";

i18n
    .use(detector)
    .use(backend)
    .use(initReactI18next)
    .init({
    fallbackLng: 'en',
    lng: 'en',
    resources: {
        en: {
            translations: require('./locales/en/translations.json')
        },
        my: {
            translations: require('./locales/my/translations.json')
        }
    },
    ns: ['translations'],
    defaultNS: 'translations'
});

i18n.languages = ['en', 'my'];

export default i18n;