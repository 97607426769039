import React, { Component } from 'react';
import { ReactSession } from 'react-client-session';
import $ from 'jquery';
import DataTable from 'react-data-table-component';

const columns = [
    {
        name: 'Title',
        sortable: true,
        selector: row => row.title,
    },
    {
        name: 'Year',
        sortable: true,
        selector: row => row.year,
    },

];
const data = [
    {
        id: 1,
        title: 'Beetlejuice',
        year: '1988',
    },
    {
        id: 2,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 3,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 4,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 5,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 6,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 7,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 8,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 9,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 10,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 11,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 12,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 13,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 14,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 15,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 16,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 17,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 18,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 19,
        title: 'Ghostbusters',
        year: '1984',
    },
    {
        id: 20,
        title: 'Ghostbusters',
        year: '1984',
    },
]

const paginationComponentOptions = {
    rowsPerPageText: 'Rows for page',
    rangeSeparatorText: 'of',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
};

export class UploadNumbers extends Component {
    static displayName = UploadNumbers.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { uploadednumbers: [], categories: [], subcategories: [], loading: true, Category_name: "", Sub_category_name:"", Category_id: "", Sub_category_id: "", Uploaded_file: "", isFilePicked: false, Numbers: "", Message: "", Link: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        if (name == 'Category_id') {
            this.Category_id = value;
            console.log(this.state.categories);
            let subcategories = this.state.categories.filter(
                (cats) => { if (cats[0].id == value) return cats; }
            );
            console.log(value);
            console.log(subcategories);
            this.setState({
                subcategories: subcategories[0],
            });
        }
    }
    componentDidMount() {
        this.populateCategories();
        //this.populateUploadNumbers();
    }

    //renderUploadNumbersTable(uploadednumbers, refreshData) {
    //    return (
    //        <Table responsive pagination={true} data-show-header="true" data-pagination="true"
    //            data-id-field="name"
    //            data-page-list="[5, 10, 25, 50, 100, ALL]"
    //            data-page-size="10" className="border table-bordered">
    //            <thead className="bg-light-gradient">
    //                <tr>
    //                    <th className="fontSmall fontBold">Category</th>
    //                    <th className="fontSmall fontBold">Number</th>
    //                    <th className="fontSmall fontBold">Status</th>
    //                </tr>
    //            </thead>
    //            <tbody>
    //                {uploadednumbers.map(x =>
    //                    <tr key={x.id}>
    //                        <td>
    //                            {x.category_name}
    //                        </td>
    //                        <td>
    //                            {x.number}
    //                        </td>
    //                        <td className="fontBold">
    //                            {x.is_active == 1 ? 'Active' : 'Inactive'}
    //                        </td>
    //                    </tr>
    //                )}
    //            </tbody>
    //        </Table>
    //    );
    //}

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        //this.populateUploadNumbers();
    }

    changeHandler = (e) => {
        console.log('changeHandler');
        console.log(e);
        this.setState({
            Uploaded_file: e.target.files[0],
            isFilePicked: true
        });
    }
    validate() {
        let messageError = "";
        if (!this.state.Category_id) {
            messageError = "Category is required";
        }
        else if (!this.state.Sub_category_id) {
            messageError = "Sub category is required";
        }
        else if (!this.state.Uploaded_file) {
            messageError = "File is required";
        }
        if (messageError) {
            this.setState({ Message: messageError });
            return false;
        }
        return true;
    }
    validateNumbers() {
        let messageError = "";
        console.log(this.state);
        if (!this.state.Category_id) {
            messageError = "Category is required";
        }
        else if (!this.state.Sub_category_id) {
            messageError = "Sub category is required";
        }
        else if (!this.state.Numbers) {
            messageError = "Numbers is required";
        }
        if (messageError) {
            this.setState({ Message: messageError });
            return false;
        }
        return true;
    }
    handleUploadFile = (e) => {
        $('.load').show();
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        if (this.validate()) {
            try {
                const formData = new FormData();
                formData.append('Category_id', this.state.Category_id);
                formData.append('Sub_category_id', this.state.Sub_category_id);
                formData.append('Uploaded_file', this.state.Uploaded_file);
                formData.append('CategoryName', $('#Category_id  option:selected').text());
                formData.append('SubCategoryName', $('#Sub_category_id  option:selected').text());

                fetch('/uploadnumbersapi/checkduplicates', {
                    //headers: {
                    //    'Content-Type': 'multipart/form-data'
                    //},
                    method: "POST",
                    body: formData,
                    //JSON.stringify({
                    //    Code: e.target.Code.value,
                    //    Name: e.target.Name.value,
                    //    Email: e.target.Email.value,
                    //    Manager_user_id: e.target.Manager.value,
                    //    Country_code: e.target.Country_code.value,
                    //    Time_zone: e.target.Time_zone.value,
                    //}),
                }).then((response) => {
                    console.log(response);
                    return response.json();
                })
                    .then((jsonData) => {
                        debugger;
                        console.log(jsonData);
                        if (jsonData.isSuccess) {
                            $('.load').hide();
                            this.setState({ Message: jsonData.message });
                            this.setState({ Link: jsonData.data });
                            this.setState({ Category_name: jsonData.Category_name });
                            this.setState({ Sub_category_name: jsonData.Sub_category_name });
                       
                           
                        }
                        else {
                            this.setState({ Message: jsonData.message });
                        }
                        //this.populateUploadNumbers();
                    },
                        (error) => {
                            this.setState({
                                Message: error
                            });
                        });
            } catch (err) {
                console.log(err);
            }
        }
    }
    handleSubmit = (e) => {
        $('.load').show();
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        if (this.validate()) {
            try {
                const formData = new FormData();
                formData.append('Category_id', this.state.Category_id);
                formData.append('Sub_category_id', this.state.Sub_category_id);
                formData.append('Uploaded_file', this.state.Uploaded_file);
                formData.append('CategoryName', $('#Category_id  option:selected').text());
                formData.append('SubCategoryName', $('#Sub_category_id  option:selected').text());


                fetch('/uploadnumbersapi/post', {
                    //headers: {
                    //    'Content-Type': 'multipart/form-data'
                    //},
                    method: "POST",
                    body: formData,
                    //JSON.stringify({
                    //    Code: e.target.Code.value,
                    //    Name: e.target.Name.value,
                    //    Email: e.target.Email.value,
                    //    Manager_user_id: e.target.Manager.value,
                    //    Country_code: e.target.Country_code.value,
                    //    Time_zone: e.target.Time_zone.value,
                    //}),
                }).then((response) => {
                    console.log(response);
                    return response.json();
                })
                    .then((jsonData) => {
                        console.log(jsonData);
                        if (jsonData.isSuccess) {
                            $('.load').hide();
                            this.setState({ Message: "Uploaded successfully" });
                        }
                        else {
                            this.setState({ Message: jsonData.message });
                        }
                        //this.populateUploadNumbers();
                    },
                        (error) => {
                            this.setState({
                                Message: error
                            });
                        });
            } catch (err) {
                console.log(err);
            }
        }
       
}



    handleSubmitNumbers = (e) => {
       
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        if (this.validateNumbers()) {
            try {
                fetch('/uploadnumbersapi/postnumbers', {
                    headers: {
                        'Authorization': 'Basic ' + ReactSession.get("token"),
                        'Content-Type': 'application/json',
                    },
                    method: "POST",
                    body: JSON.stringify({
                        Category_id: this.state.Category_id,
                        Sub_category_id: this.state.Sub_category_id,
                        Numbers: this.state.Numbers,
                    }),
                }).then((response) => {
                    return response.json();
                })
                    .then((jsonData) => {
                        console.log(jsonData);
                        if (jsonData.isSuccess) {

                            this.setState({ Message: "Uploaded successfully" });
                        }
                        else {
                            this.setState({ Message: jsonData.message });
                        }
                    },
                        (error) => {
                            this.setState({
                                Message: error
                            });
                        });
            } catch (err) {
                console.log(err);
            }
        }
    }
    render() {
        //let contents = this.state.loading
        //    ? <p><em>Loading...</em></p>
        //    : this.renderUploadNumbersTable(this.state.uploadednumbers, this.refreshData);
       
        return (
            <div className="row m-0 px-3 mt-3">
             {/*   <DataTable fixedHeader fixedHeaderScrollHeight="300px" columns={columns} data={data} pagination />*/}


                <div className="col-xl-12">
                    {/*    <h4 className="my-3">UploadNumbers</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Upload Numbers</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Numbers</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Upload Numbers</li>
                            </ol>
                        </div>
                    </div>

                </div>
                <div className="col-xl-12">
                    <div className="load text-center" style={{ 'display': 'none' }}>
                        <img src="/images/upload-numbers-image.gif" alt="loading icon" width="25%" />
                        <p className="text-center">Uploading data... <br />It will take few seconds. Please sit back.</p>
                    </div>
                    <div className="load2 text-center" style={{ 'display': 'none' }}>
                        <img src="/images/success-data.gif" alt="loading icon" className="success-data" style={{ 'display': 'block' }} />
                        <h5 className="text-center">Data Uploaded.</h5>
                    </div>
                    <div className={this.state.Message ? "alert alert-success alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                        <strong>
                            {this.state.Message}
                           
                        </strong>
                        {this.state.Category_id}{this.state.Sub_category_id}
                    </div>
                    <div className="row row-sm">
                        <div className="col-lg-12">
                            <div className="custom-card">
                                <div className="row">
                                    <div className="col-xl-5">
                                        <div className="card p-4 mb-4">
                                            <form encType="multipart/form-data" onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="pb-2">Upload Numbers Sheet</h4>
                                                        <div className="form-group mb-2">
                                                            <label className="form-label">Category Name:</label>
                                                            <div className="input-group">
                                                                <select name="Category_id" id="Category_id" onChange={this.handleInputChange} defaultValue={this.state.Category_id} className="required-entry form-control">
                                                                    <option value="">Select</option>
                                                                    {this.state.categories.map(cat =>
                                                                        <option key={cat[0].id} value={cat[0].id}>{cat[0].name}</option>
                                                                    )}
                                                                </select>
                                                            </div>                                                          
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12"></div>
                                                    <div className="form-group mb-2">
                                                    <div className="col-12">
                                                        <label className="form-label">Sub Category Name:</label>
                                                        <div className="input-group">
                                                            <select name="Sub_category_id" id="Sub_category_id" onChange={this.handleInputChange} defaultValue={this.state.Sub_category_id} className="required-entry form-control">
                                                                <option value="">Select</option>
                                                                {this.state.subcategories.map(subcat =>
                                                                    <option key={subcat.sub_category_id} value={subcat.sub_category_id}>{subcat.sub_category_name}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Upload Numbers Sheet:</label>
                                                            {/*<input type="file" className="form-control" name="file" placeholder="Upload Sheet" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />*/}
                                                            <input type="file" className="form-control" name="Uploaded_file" id="Uploaded_file" onInput={this.changeHandler} onChange={this.handleUploadFile} />
                                                            {this.state.isFilePicked ? (
                                                                <div>
                                                                    <p>Filename: {this.state.Uploaded_file.name}</p>
                                                                    <p>Filetype: {this.state.Uploaded_file.type}</p>
                                                                    <p>Size in bytes: {this.state.Uploaded_file.size}</p>
                                                                    <p>
                                                                        lastModifiedDate:{' '}
                                                                        {this.state.Uploaded_file.lastModifiedDate.toLocaleDateString()}
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <p>Select a file to show details</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-left mt-3">
                                                    
                                                    <button type="submit" className="btn btn-rounded btn-success">Upload</button>
                                                    <div className={this.state.Link ? "alert alert-info alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                                                        <strong>
                                                            <a href={this.state.Link} className="text-dark" download>Download Duplicate Numbers</a>
                                                        </strong>
                                                    </div>
                                                </div>
                                                <p>&nbsp;</p>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="col-xl-1 text-center">
                                        <p className="d-xl-block d-lg-block d-md-block">&nbsp;</p> <p className="d-none d-xl-block d-lg-block d-md-block">&nbsp;</p> <p className="d-none d-xl-block d-lg-block d-md-block">&nbsp;</p> <p className="d-none d-xl-block d-lg-block d-md-block">&nbsp;</p><p className="d-none d-xl-block d-lg-block d-md-block">&nbsp;</p><p className="d-none d-xl-block d-lg-block d-md-block">&nbsp;</p>
                                        OR
                                    </div>
                                    <div className="col-xl-5">
                                        <div className="card p-4">
                                            <form onSubmit={this.handleSubmitNumbers}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="pb-2">Add Numbers</h4>
                                                        <div className="form-group mb-2">
                                                            <label className="form-label">Category Name:</label>
                                                            <div className="input-group">
                                                                <select name="Category_id" id="Category_id" onChange={this.handleInputChange} defaultValue={this.state.Category_id} className="required-entry form-control">
                                                                    <option value="">Select</option>
                                                                    {this.state.categories.map(cat =>
                                                                        <option key={cat[0].id} value={cat[0].id}>{cat[0].name}</option>
                                                                    )}
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="form-group mb-2">
                                                            <label className="form-label">Sub Category Name:</label>
                                                        <div className="input-group">
                                                            <select name="Sub_category_id" id="Sub_category_id" onChange={this.handleInputChange} defaultValue={this.state.Sub_category_id} className="required-entry form-control">
                                                                <option value="">Select</option>
                                                                {this.state.subcategories.map(subcat =>
                                                                    <option key={subcat.sub_category_id} value={subcat.sub_category_id}>{subcat.sub_category_name}</option>
                                                                )}
                                                            </select>
                                                        </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-group mb-2">
                                                            <label className="form-label">Add Numbers:</label>
                                                            <div className="input-group">
                                                                <textarea rows="5" name="Numbers" onChange={this.handleInputChange} placeholder="Add numbers here..." className="border w-100 small p-2"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="text-left mt-3">
                                                    <button type="submit" className="btn btn-rounded btn-success">Submit Numbers</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12 mt-5 mb-5 d-none">
                            <div className="card custom-card">
                                <div className="card-body">
                                    {/*{contents}*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ categories: data.data, loading: false });
        }
    }
    //async populateUploadNumbers() {
    //    const response = await fetch('/uploadnumbersapi');
    //    const data = await response.json();
    //    console.log(data.data);
    //    this.setState({ uploadednumbers: data.data, loading: false });
    //}
}

export default UploadNumbers;

