import React, { Component, useState, PureComponent } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import { ReactSession } from 'react-client-session' ;
import $ from 'jquery';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell, Tooltip, BarChart, Bar, XAxis, YAxis, CartesianGrid, Legend } from 'recharts';


const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const { fill, x, y, width, height } = props;
    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF9043', '#FF1044', '#FF2045', '#FF3046', '#FF8047'];
export class Dashboard extends Component {
    static displayName = Dashboard.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { SampleRequests: [], mapdata: [], monthgraph: [], yeargraph: [], masterwallet: "", salesgraph: [], dprecords: "", users: "", samplecount: "", weeklysales: "", mothlysales: "", totalSales: "", uniquenumberscount: "", specialnumberscount: "", loading: true, };


    }
    componentDidMount() {
        //this.populateDashboard();
        this.populateMasterWallet();
        /*this.populateSampleRequests();*/
        //const script = document.createElement("script");
        //script.src = "~/images/pie-chart.js";
        //script.id = 'pie-chart';
        //script.async = true;

    }

    renderDashboardTable(SampleRequests, refreshData) {

        
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">Name</th>
                        <th className="fontSmall fontBold">Mobile Number</th>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">Sub Category</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.SampleRequests.length > 0 ? this.state.SampleRequests.map(x =>(
                        <tr key={x.id}>
                            <td>
                                {x.user_name}
                            </td>
                            <td>
                                {x.mobile_number}
                            </td>
                            <td>
                                {x.category_name}
                            </td>
                            <td>
                                {x.sub_category_name}
                            </td>
                        </tr>
                    )) : <tr><td colspan="4"><div className="text-center fontBold"> No Sample Requests are noted.</div></td></tr>}
                </tbody>
            </Table>
        );
    }
    
    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateMasterWallet();
        /* this.populateSampleRequests();*/
    };
    render() {
        /* Graph script for weekly monthly and total*/
        $('.m-show').click(function () {
            $('.monthly-div').show();
            $('.weekly-div, .total-div').hide();
            $('.w-show, .t-show').removeClass('active');
            $('.m-show').addClass('active');
        })
        $('.t-show').click(function () {
            $('.total-div').show();
            $('.weekly-div, .monthly-div').hide();
            $('.m-show, .w-show').removeClass('active');
            $('.t-show').addClass('active');
        })
        $('.w-show').click(function () {
            $('.weekly-div').show();
            $('.total-div, .monthly-div').hide();
            $('.t-show, .m-show').removeClass('active');
            $('.w-show').addClass('active');
        })
        /* Graph script for weekly monthly and total ends*/
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderDashboardTable(this.state.SampleRequests, this.refreshData);
        return (

            <div className="row m-0 px-3 mt-5 mb-5">

                <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark pointer" tag={Link} to="/superadmin/masterwallet" >
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Wallet</h6>
                                                <h3 className="number-font">Gp {this.state.masterwallet} </h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-primary-gradient box-shadow-primary brround ms-auto">
                                                    <i className="icon icon-wallet text-white mb-5"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark pointer" tag={Link} to="/superadmin/samplerequests" >
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Pending Sample Requests</h6>
                                                <h3 className="number-font">
                                                    {this.state.samplepending ? this.state.samplepending : "0" }</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-danger-gradient box-shadow-danger brround ms-auto"><i className="icon icon-list text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark pointer" tag={Link} to="/superadmin/DepositRequests" >

                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Deposite Requests</h6>
                                                <h3 className="number-font">{this.state.dprecords}</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-success-gradient box-shadow-success brround ms-auto"><i className="icon icon-handbag text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4 mt-4">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark pointer" tag={Link} to="/superadmin/clients" >

                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Users</h6>
                                                <h3 className="number-font">{this.state.users}</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-success-gradient box-shadow-success brround ms-auto"><i className="icon icon-people text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4 mt-4">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark" tag={Link} to="/superadmin/uniquenumberslist" >

                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Unique Numbers</h6>
                                                <h3 className="number-font">{this.state.uniquenumberscount}</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-info-gradient box-shadow-success brround ms-auto"><i className="icon icon-phone text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div><div className="col-lg-6 col-md-12 col-sm-12 col-xl-4 mt-4">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark" tag={Link} to="/superadmin/specialnumberslist" >

                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Special Numbers</h6>
                                                <h3 className="number-font">{this.state.specialnumberscount}</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-info-gradient box-shadow-success brround ms-auto"><i className="icon icon-phone text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 pt-5 pb-5">
                            <h4 className="pb-2">Recent Sample Requests</h4>
                            <div className="card custom-card">
                                <div className="card-body">
                                    {contents}
                                </div>
                            </div>
                        </div>



                        <div className="col-12">
                            <h4>Sales</h4>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4 mt-2">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark pointer" tag={Link} to="/superadmin/DepositRequests" >
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Weekly</h6>
                                                <h3 className="number-font">{this.state.weeklysales}</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-warning-gradient box-shadow-warning brround ms-auto"><i className="icon icon-basket text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4 mt-2">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark pointer" tag={Link} to="/superadmin/DepositRequests" >
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Monthly</h6>
                                                <h3 className="number-font">{this.state.mothlysales}</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-warning-gradient box-shadow-warning brround ms-auto"><i className="icon icon-basket text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4 mt-2">
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <NavLink className="nav-link text-dark pointer" tag={Link} to="/superadmin/DepositRequests" >
                                        <div className="row">
                                            <div className="col">
                                                <h6 className="">Total</h6>
                                                <h3 className="number-font">{this.state.totalSales}</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-warning-gradient box-shadow-warning brround ms-auto"><i className="icon icon-basket text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </NavLink>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4 mt-2">
                            <div className="mt-4 mb-3"><h4>Data Graph</h4></div>
                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="report-wrapper">
                                                <a href="javascript:;" className="w-show active">Weekly</a>
                                                <a href="javascript:;" className="m-show">Monthly</a>
                                                <a href="javascript:;" className="t-show">Total</a>
                                            </div>
                                            <div className="weekly-div">
                                                <div id="pie-chart" ><PieChart width={400} height={300}>
                                                    {/*   <Pie data={data} dataKey="students" outerRadius={150} fill="red" />*/}
                                                    <Pie innerRadius={40} outerRadius={90} data={this.state.mapdata} dataKey="totalsales" nameKey="name" cx="40%" cy="50%" fill="#8884d8" label >
                                                        {this.state.mapdata.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={colors[index]} />

                                                        ))}
                                                    </Pie>
                                                    <Tooltip />
                                                </PieChart>
                                                </div>
                                            </div>
                                            <div className="monthly-div" style={{ 'display': 'none' }}>
                                                <div id="pie-chart" ><PieChart width={400} height={300}>
                                                    {/*   <Pie data={data} dataKey="students" outerRadius={150} fill="red" />*/}
                                                    <Pie innerRadius={40} outerRadius={90} data={this.state.monthgraph} dataKey="totalsales" nameKey="name" cx="40%" cy="50%" fill="#8884d8" label >
                                                        {this.state.monthgraph.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={colors[index]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip />
                                                </PieChart>
                                                </div>
                                            </div>
                                            <div className="total-div" style={{ 'display': 'none' }}>
                                                <div id="pie-chart" ><PieChart width={400} height={300}>
                                                    {/*   <Pie data={data} dataKey="students" outerRadius={150} fill="red" />*/}
                                                    <Pie innerRadius={40} outerRadius={90} data={this.state.yeargraph} dataKey="totalsales" nameKey="name" cx="40%" cy="50%" fill="#8884d8" label >
                                                        {this.state.yeargraph.map((entry, index) => (
                                                            <Cell key={`cell-${index}`} fill={colors[index]} />
                                                        ))}
                                                    </Pie>
                                                    <Tooltip />
                                                </PieChart>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-xl-8 mt-2">
                            <div className="mt-4 mb-3"><h4>Sales Graph</h4></div>

                            <div className="card overflow-hidden">
                                <div className="card-body pb-2">
                                    <div className="row">
                                        <div className="col-12">
                                            {/*<BarChart*/}
                                            {/*    width={700}*/}
                                            {/*    height={320}*/}
                                            {/*    data={this.state.salesgraph}*/}
                                            {/*    margin={{*/}
                                            {/*        top: 20,*/}
                                            {/*        right: 30,*/}
                                            {/*        left: 20,*/}
                                            {/*        bottom: 5,*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <CartesianGrid strokeDasharray="3 3" />*/}
                                            {/*    <XAxis dataKey="name" />*/}
                                            {/*    <YAxis />*/}
                                            {/*    <Bar dataKey="totalsales" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>*/}
                                            {/*        {this.state.salesgraph.map((entry, index) => (*/}
                                            {/*            <Cell key={`cell-${index}`} fill={colors[index]} />*/}
                                            {/*        ))}*/}
                                            {/*    </Bar>*/}
                                            {/*</BarChart>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div >
        )
    }

    async populateMasterWallet() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/masterwalletapi/DashboardAdmin', {
            headers: {
                'Authorization': 'Basic ' + ReactSession.get("token"),
                'Content-Type': 'application/json',
            },
            method: "get",
            // data:  person,
        });
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ masterwallet: data.data.masterwallet, samplecount: data.data.samplecount, dprecords: data.data.dprecords, users: data.data.users, weeklysales: data.data.weeklysales, mothlysales: data.data.mothlysales, totalSales: data.data.totalSales, specialnumberscount: data.data.specialnumberscount, uniquenumberscount: data.data.uniquenumberscount, SampleRequests: data.data.objDyns, loading: false, usermobile: data.data.usermobile, mapdata: data.data.objgraphDyns, monthgraph: data.data.objgraphDynsmonth, yeargraph: data.data.objgraphDynsyear, salesgraph: data.data.objsalesgraphDynsweek });
        } }

}

export default Dashboard;

