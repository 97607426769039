import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";


const SwitchToggle = props => {
    const [checked, setChecked] = useState(props.editRec.is_active == 1 ? true : false);
    const handleChange = (nextChecked) => {
        console.log(props);
        setChecked(nextChecked);
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/userapi/updatestatus', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: props.editRec.id,
                    status: nextChecked
                }),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="example">
            <label>
                <Switch
                    onChange={handleChange}
                    checked={checked}
                    className={"react-switch"}
                />
            </label>
            <span>{checked}</span>
        </div>
    );
};

export class SellerWithdrawalList extends Component {
    static displayName = SellerWithdrawalList.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { users: [], loading: true, selectedrec: "", usersinactive: [], loading2: true };

    }

    componentDidMount() {
        this.populateSellerWithdrawalList();
    }

    renderSellerWithdrawalListTable(users, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th class="fontSmall fontBold">User Name</th>
                        <th class="fontSmall fontBold">Seller Name</th>
                        <th class="fontSmall fontBold">Category</th>
                        <th class="fontSmall fontBold">Phone Number</th>
                        <th class="fontSmall fontBold">Date &amp; Time</th>
                        <th class="fontSmall fontBold">Amount</th>
                        <th class="fontSmall fontBold">% Amount</th>
                        <th class="fontSmall fontBold">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user =>
                        <tr key={user.id}>
                            <td>Kim</td>
                            <td>John</td>
                            <td>Film</td>
                            <td>5345435</td>
                            <td className="font-weight-bold">12 May 2022 10:00 AM</td>
                            <td className="font-weight-bold">2,000</td>
                            <td>350</td>
                            <td>
                                <a className="btn btn-success text-white" onclick="suc();"><i className="fa fa-check-circle"></i>&nbsp; Accept</a>&nbsp;&nbsp;
                                <a className="btn btn-danger text-white" onclick="rej();"><i className="fa fa-times-circle"></i>&nbsp; Reject</a>
                            </td>
                            {/*<td>*/}
                            {/*    {user.name}*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*    {user.mobile_number}*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*    {user.email}*/}
                            {/*</td>*/}
                            {/*<td className="fontBold">*/}
                            {/*    {user.is_active == 1 ? 'Active' : 'Inactive'}*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*    <SwitchToggle callbackFn={refreshData} editRec={user} />*/}
                            {/*</td>*/}
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateSellerWithdrawalList();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderSellerWithdrawalListTable(this.state.users, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/* <h4 className="my-3">SellerWithdrawalList</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Seller Withdrawal List</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Seller </a></li>
                                <li className="breadcrumb-item active" aria-current="page">Seller Withdrawal List</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 tabCustom mb-5 mt-2">
                    <div className="container  px-0 tab-pane active">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                <div class="col-12">
                                    <div class="pull-right mb-2">
                                            <a href="#" class="btn btn-success"><i class="fa fa-download"></i>&nbsp; Download Report</a>
                                    </div>
                                </div>
                                {contents}
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateSellerWithdrawalList() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/userapi', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ users: data.data, loading: false });
        }
    }
}

export default SellerWithdrawalList;

