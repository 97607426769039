import React, { Component, useState } from 'react'
import { ReactSession } from 'react-client-session';
import 'bootstrap/dist/css/bootstrap.css';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import $ from "jquery";
import { NavLink } from 'reactstrap';
import dateFormat, { masks } from "dateformat";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../root/css/style.css';
import '../root/dashboard/vendors/feather/feather.css';
import '../root/dashboard/vendors/mdi/css/materialdesignicons.min.css';
import '../root/dashboard/vendors/ti-icons/css/themify-icons.css';
import '../root/dashboard/vendors/typicons/typicons.css';
import '../root/dashboard/vendors/simple-line-icons/css/simple-line-icons.css';
import '../root/dashboard/vendors/css/vendor.bundle.base.css';
import '../root/dashboard/js/select.dataTables.min.css';
import '../root/dashboard/css/vertical-layout-light/style.css';
import '../root/dashboard/css/style.css';
//import { format, intervalToDuration } from 'date-fns';
//import intervalToDuration from "date-fns/intervalToDuration.mjs";


export class Mining_Requests extends Component {
    constructor() {
        super();
        this.state = { categories: [], loading: true, selectedrec: "", Url: "",No_Of_Leads:"",Email:"" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        this.setState({ Email: ReactSession.get("username") });
    }
    componentDidMount() {
        this.populateCategories();
    }
    refreshData = (data) => {
        this.populateCategories();
    };
    renderCategoriesTable(categories, refreshData) {
        return (
            <Table datatable responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">Url</th>
                        <th className="fontSmall fontBold">Leads</th>
                        <th className="fontSmall fontBold">Requested On</th>
                        <th className="fontSmall fontBold">Delivery Date</th>
                        <th className="fontSmall fontBold">View Status</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map(cat =>
                        <tr key={cat.id}>
                            <td>
                                {cat[0].url}
                            </td>
                            <td>
                                {cat[0].requested_Leads}
                            </td>
                            <td>
                                {(dateFormat(new Date(cat[0].request_Date), 'dd-mmm-yyyy')) }
                            </td>
                            <td>
                                {cat[0].delivery_Date == null ? ("Will be updated soon") : (dateFormat(new Date(cat[0].delivery_Date), 'dd-mmm-yyyy'))}
                            </td>
                            
                            <td className="fontBold">
                                <NavLink tag={Link} to={`/user/mining_requests_detail?id=${cat[0].id}`}  className="w-50"><span className="btn btn-success btn-sm">View</span></NavLink>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }
    handleSubmit = (e) => {
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/category/postmining', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Url: this.state.Url,
                    Requested_Leads: this.state.No_Of_Leads,
                    Email_ID:this.state.Email
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.setState({ Message: jsonData.message });
                        this.populateCategories();
                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
   
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderCategoriesTable(this.state.categories, this.refreshData);
        return (
            


            <div className="row m-0 p-3">
                <div className="col-xl-12 my-2">
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Request Mining Leads</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Mining Leads</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Request Mining Leads</li>
                            </ol>
                        </div>
                    </div>
                    {/*<h4 className="fontBold">Create Category</h4>*/}
                    {/*<a className="f-12 text-decoration-none">Category</a> <span className="f-12 mx-2">/</span><a className="f-12 text-primary text-decoration-none">Create Category</a>*/}
                </div>
                <div className="col-lg-12">
                    <div className={this.state.Message ? "alert alert-success alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                        <strong>
                            {this.state.Message}
                        </strong>
                    </div>
                    <div class="alert alert-danger d-flex align-items-center" role="alert">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
                            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                        </svg>
                        <div class="text-dark"><strong>ATTENTION:</strong> Explore the full potential of our data mining engine! To maximize URL mining effectiveness, ensure a minimum URL with monthly traffic of minimum 300,000. Uncover valuable insights effortlessly. Get started today and transform your data into actionable&nbsp;intelligence!</div>
                    </div>

                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-12">
                                
                                        <div className="form-group">
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="pb-1">
                                                        <label className="form-label mb-2">URL:</label>
                                                        <input className="form-control" name="Url" autoComplete="off" id="Url" onChange={this.handleInputChange} placeholder="Enter Url" type="text" />
                                                    </div>
                                                   </div>
                                                    <div className="col-sm-4">
                                                        <div className="pb-1">
                                                        <label className="form-label mb-2">Leads:</label>
                                                        <input className="form-control" name="No_Of_Leads" autoComplete="off" id="No_Of_Leads" onChange={this.handleInputChange} placeholder="Enter No of Leads" type="number"
                                                            min="50000"
                                                            max="100000000000000000" />
                                                        </div>
                                                        </div>
                                                    <div className="col-sm-4 form-group">
                                                        <div className="pb-1">
                                                        <label className="form-label mb-2">Email:</label>
                                                        <input className="form-control" name="Email" autoComplete="off" id="Email" onLoad={this.handleInputChange} value={ReactSession.get("email")} readOnly placeholder="Enter Email Id" type="text" />
                                                        </div>
                                                        </div>
                                                    <div className="col-sm-12">
                                                        <button className="btn btn-warning" type="submit">Start Mining</button>
                                                    </div>
                                                </div>
                                            </form>
                                      
                                    </div>
                                </div>
                                <div className="col-12"></div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="">
                    <div className="mt-3">
                        <div className="tabCustom mb-5">
                            <div className="card custom-card">
                                <div className="card-body">
                                    {contents}
                                </div>
                            </div></div>
                    </div>
                </div>

            </div>
        )
    }
    async populateCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/getminingrequets', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ categories: data.data, loading: false });
        }
    }
}

export default Mining_Requests;