import React, { Component, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";

export class SampleRequests extends Component {
    static displayName = SampleRequests.name;

    constructor(props) {
        super(props);
        this.state = { SampleRequests: [], loading: true, selectedrec: "" };
    }

    componentDidMount() {
        this.populateSampleRequests();
    }
    updateSQ(Id, status) {
        var obj = { "Id": "" + Id + "", Status: "" + status + "" };
        try {
            fetch('/category/updatesamplereq', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                // data:  person,
                body: JSON.stringify(obj),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    this.populateSampleRequests();
                    // console.log(props);
                    //props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    }
    getStatus = (x) => {
        var status = '';
        if (x.req_status == 0) {
            status = 'Pending';
        }
        else if (x.req_status == 1) {
            status = 'Approved';
        }
        else if (x.req_status == 9) {
            status = 'Rejected';
        }
        return status;
    }

    renderSampleRequestsTable(SampleRequests, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">User</th>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">SubCategory</th>
                        <th className="fontSmall fontBold">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {SampleRequests.map(x =>
                        <tr key={x.id}>
                            <td style={{ width: '25%' }}>
                                {x.user_name}
                            </td>
                            <td style={{ width: '25%' }}>
                                {x.category_name}
                            </td>
                            <td style={{ width: '25%' }}>
                                {x.sub_category_name}
                            </td>
                            <td className="fontBold">
                                {(() => {
                                    if (x.req_status == 0) {
                                        return (
                                            <a className="badge badge-warning"> {this.getStatus(x)}</a>
                                        )
                                    }
                                    else if (x.req_status == 1) {
                                        return (
                                            <a className="badge badge-success"><i className="fa fa-check"></i> {this.getStatus(x)}</a>
                                        )
                                    }
                                    else if (x.req_status == 9) {
                                        return (
                                            <a className="badge badge-danger"><i className="fa fa-times"></i> {this.getStatus(x)}</a>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (x.req_status == 0) {
                                        return (
                                            <div>
                                                <a onClick={() => { this.updateSQ(x.id, 1) }} className="badge badge-success" style={{cursor: 'pointer' }}><i className="fa fa-check"></i> Approve</a>
                                                <a onClick={() => { this.updateSQ(x.id, 9) }} className="badge badge-danger" style={{  cursor: 'pointer' }}><i className="fa fa-times"></i> Reject</a>
                                            </div>
                                        )
                                    }
                                })()}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateSampleRequests();
        this.populateInactiveSampleRequests();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderSampleRequestsTable(this.state.SampleRequests, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/* <h4 className="my-3">Sample Requests</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Sample Requests</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Requests</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Sample Requests</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 tabCustom mb-5">
                    <div className="container px-0 tab-pane active">
                        <div className="card custom-card">
                            <div className="card-body">
                                {contents}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateSampleRequests() {
        const response = await fetch('/category/samplerequests');
        const data = await response.json();
        console.log(data.data);
        this.setState({ SampleRequests: data.data, loading: false });
    }
}

export default SampleRequests;

