import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import GDPBlankLayout from './gdp/layouts/GDPBlankLayout';
import UserLayout from './gdp/layouts/UserLayout';
import SellerLayout from './gdp/layouts/SellerLayout';
import AdminLayout from './gdp/layouts/AdminLayout';
import SuperAdminLayout from './gdp/layouts/SuperAdminLayout';
import { Login } from './gdp/Login'
import { Register } from './gdp/Register'
import { UserRegistration } from './gdp/UserRegistration'



export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <div className="App">
                <BrowserRouter>
                    <Switch>
                        <Route exact path="/" render={(props) => <GDPBlankLayout {...props} />} />
                        <Route path='/login' render={(props) => <GDPBlankLayout {...props} />} component={Login} />
                        <Route path='/register' render={(props) => <GDPBlankLayout {...props} />} component={Register} />
                        <Route path='/userregistration' render={(props) => <GDPBlankLayout {...props} />} component={UserRegistration} />
                        <Route path='/user' render={(props) => <UserLayout {...props} />} />
                        <Route path='/seller' render={(props) => <SellerLayout {...props} />} />
                        <Route path='/admin' render={(props) => <AdminLayout {...props} />} />
                        <Route path='/superadmin' render={(props) => <SuperAdminLayout {...props} />} />
                    </Switch>
                </BrowserRouter>
            </div>
        );
    }
}
