import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Image from 'react-bootstrap/Image';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";


export class ArchiveHistory extends React.Component {
    static displayName = ArchiveHistory.name;
    constructor(props) {
        super(props);
        this.state = { ArchiveHistory: [], categoryname: "", subcategoryname: "", filename: "", totalnumbersuploaded: "", totalduplicates: "", finaluploadednumbers: "", date: "", loading: true };
    }
    state = {
        modal: false
    }
    state = {
        enabled: false
    }

    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }



    componentDidMount() {
        this.populateArchiveHistory();
    }

    renderArchiveHistoryTable(archivehistory, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">S.No</th>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">Sub Category</th>
                        <th className="fontSmall fontBold">Date</th>
                        <th className="fontSmall fontBold">Total Numbers</th>
                        <th className="fontSmall fontBold">Duplicates</th>
                        <th className="fontSmall fontBold">Uploaded Numbers</th>
                        <th className="fontSmall fontBold">Document</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.ArchiveHistory.length > 0 ? this.state.ArchiveHistory.map((archivehistorydata, idx) => (
                        <tr key={archivehistorydata.id}>
                            <td> {idx + 1}</td>
                            <td>{archivehistorydata.categoryname}</td>
                            <td>{archivehistorydata.subcategoryname} </td>
                            <td>{archivehistorydata.date} </td>
                            <td>{archivehistorydata.totalnumbersuploaded}</td>
                            <td>{archivehistorydata.totalduplicates}</td>
                            <td>{archivehistorydata.finaluploadednumbers}</td>
                            <td><a href={"uploadednumbers/" + archivehistorydata.filename} download className="text-dark"><i class="fa fa-download text-success"></i> Download</a></td>
                        </tr>
                    )) : <tr><td colspan="7"><div className="text-center fontBold"> No Details are noted.</div></td></tr>
                    }
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateArchiveHistory();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderArchiveHistoryTable(this.state.ArchiveHistory, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/*  <h4 className="my-3">ArchiveHistory</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Archive History</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">History </a></li>
                                <li className="breadcrumb-item active" aria-current="page">Archive History</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 tabCustom mb-5 mt-2">
                    <div className="container  px-0 tab-pane active">
                        <div className="card custom-card">
                            <div className="card-body">
                                {contents}
                            </div>
                        </div></div></div>
            </div>
        )
    }
    async populateArchiveHistory() {
        debugger
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/archivehistory', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ ArchiveHistory: data.data, loading: false });
        }
    }
}

export default ArchiveHistory;

