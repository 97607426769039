import React from 'react'
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import { ReactSession } from 'react-client-session';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/free-regular-svg-icons'

export class Dashboard extends React.Component {
    static displayName = Dashboard.name;
    constructor() {
        super();
        this.state = { categories: [], loading: true, subcategories: [], Category_id: "", Sub_category_id: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.Category_id = "";
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        if (name == 'Category_id') {
            this.Category_id = value;
            console.log(this.state.categories);
            let subcategories = this.state.categories.filter(
                (cats) => { if (cats[0].id == value) return cats; }
            );
            console.log(value);
            console.log(subcategories);
            this.setState({
                subcategories: subcategories[0],
            });
        }
    }
    componentDidMount() {
    }


    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    toggleOffcanvas() {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    }
    toggleRightSidebar() {
        document.querySelector('.right-sidebar').classList.toggle('open');
    }
    signOutClick() {
        localStorage.clear();
        window.location.href = "/"
    }

    state = {
        modal: false
    }

    selectModal = (info) => {
        this.setState({ modal: !this.state.modal });
    }

    sendRequestForm = () => {
        $('.scat').show();
        this.populateCategories();
    }
    handleSubmit = (e) => {
        e.preventDefault();
        try {
            fetch('/category/samplerequest', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Category_id: this.state.Category_id,
                    Sub_category_id: this.state.Sub_category_id,
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        $('.scat, .sreq').hide();
                        $('.alert-success').show();
                        $('.sentr').show();
                        $('.float').show();
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        const { match } = this.props

        return (
            <div className="side-app mt-5">
                    {/*<div className="alert alert-success alert-dismissible text-center" style={{ marginTop: '18px' }}>*/}
                    {/*    <a href="#" className="close" data-dismiss="alert" aria-label="close" title="close">�</a>*/}
                    {/*    <strong>*/}
                    {/*        Sample request for the database is successfully sent.*/}
                    {/*    </strong>*/}
                    {/*</div>*/}
                <div className="pl-4 pr-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4">
                                    <a href="Wallet.html" className="card overflow-hidden text-dark">
                                        <div className="card-body pb-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h6>Wallet</h6>
                                                    <h3 className="number-font">3400</h3>
                                                </div>
                                                <div className="col col-auto">
                                                    <div className="counter-icon bg-primary-gradient box-shadow-primary brround ms-auto">
                                                        <i className="icon icon-wallet text-white mb-5"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4">
                                    <a href="purchase-history.html" className="card overflow-hidden text-dark">
                                        <div className="card-body pb-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h6>Purchased</h6>
                                                    <h3 className="number-font">34</h3>
                                                </div>
                                                <div className="col col-auto">
                                                    <div className="counter-icon bg-danger-gradient box-shadow-danger brround ms-auto"><i className="icon fa fa-database text-white mb-5"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4">
                                    <div className="float blink" style={{ display: 'none'}}>You will receive 1000 numbers</div>
                                    <div className="card overflow-hidden">
                                        <div className="card-body pb-2">
                                            <div className="row">
                                                <div className="col">
                                                    <h6>Sample Data Request</h6>
                                                    <a style={{ cursor: 'pointer' }} onClick={this.sendRequestForm} className="badge bg-success badge-success sreq">Send Request <i className="fa fa-paper-plane"></i></a>
                                                    <a className="badge bg-gray-dark-light text-white sentr" style={{ display: 'none' }} disabled> <i className="fa fa-check-circle-o"></i> Request Sent</a>
                                                </div>
                                                <div className="col col-auto">
                                                    <div className="counter-icon bg-success-gradient box-shadow-success brround ms-auto"><i className="fe fe-briefcase text-white mb-5"></i></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container scat mt-4" style={{ display: 'none' }}>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="card overflow-hidden">
                                        <div className="card-body pb-2">
                                            <form onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-sm-6 col-md-6">
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-5">
                                                                    <label className="form-label">Select Category:</label>
                                                                </div>
                                                                <div className="col-lg-7">
                                                                    <div className="input-group">
                                                                        <select name="Category_id" id="Category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                                            <option value="">Select</option>
                                                                            {this.state.categories.map(cat =>
                                                                                <option value={cat[0].id}>{cat[0].name}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 form-group"></div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-lg-5">
                                                                    <label className="form-label">Select Sub Category:</label>
                                                                </div>
                                                                <div className="col-lg-7">
                                                                    <div className="input-group">
                                                                        <select name="Sub_category_id" id="Sub_category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                                            <option value="">Select</option>
                                                                            {this.state.subcategories.map(subcat =>
                                                                                <option value={subcat.sub_category_id}>{subcat.sub_category_name}</option>
                                                                            )}
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12"></div>
                                                <div className="col-sm-6 col-md-6 mt-3">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-lg-5">
                                                                <label className="form-label">&nbsp;</label>
                                                            </div>
                                                            <div className="col-lg-7">
                                                                <div className="input-group">
                                                                    <button type="submit" className="btn btn-rounded btn-primary">Send Request</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
    async populateCategories() {
        const response = await fetch('/category');
        const data = await response.json();
        console.log(data.data);
        this.setState({ categories: data.data, loading: false });
    }
}


export default Dashboard