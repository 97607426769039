import React, { Component, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { ReactSession } from 'react-client-session';
import $ from 'jquery';

export class DepositRequests extends Component {
    static displayName = DepositRequests.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { depositrequests: [], loading: true, Quantity: "" };
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    componentDidMount() {
        this.populateDepositRequests();
    }
    updateDQ(Id, status, state) {
        console.log(state);
        var unique_number;
        var qty = 0;
        if (status == 1) {
            qty = state['Quantity' + Id];
            unique_number = state['Unique_number' + Id];
            alert('working');
            $('.load').hide();
        }
       
       /* $(".load").hide();*/
        var obj = { "Id": "" + Id + "", Status: "" + status + "", Quantity: "" + qty + "", Unique_number: "" + unique_number + "" };
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");

        try {
            fetch('/category/updatedepositreq', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                // data:  person,
                body: JSON.stringify(obj),
            }).then((response) => {
                return response.json();

            })
                .then((jsonData) => {
                    if (jsonData.isSuccess) {
                        this.setState({ Message: jsonData.message });
                        this.populateDepositRequests();
                        $(".load").hide();
                        // console.log(props);
                        //props.callbackFn(data);
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                        $(".load").hide();
                    }
                });
        } catch (err) {
            console.log(err);
        }
        $(".load").show();
    }
    getStatus = (x) => {
        var status = '';
        if (x.req_status == 0) {
            status = 'Pending';
        }
        else if (x.req_status == 1) {
            status = 'Approved';
        }
        else if (x.req_status == 9) {
            status = 'Rejected';
        }
        return status;
    }
    renderDepositRequestsTable(depositrequests, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">User</th>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">SubCategory</th>
                        <th className="fontSmall fontBold">Quantity</th>
                        <th className="fontSmall fontBold">Amount</th>
                        <th className="fontSmall fontBold">Request Date</th>
                        <th className="fontSmall fontBold">Approved Quantity</th>
                        <th className="fontSmall fontBold">Unique number</th>
                        <th className="fontSmall fontBold">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {depositrequests.map(x =>
                        <tr key={x.id}>
                            <td>
                                {x.user_name}
                            </td>
                            <td>
                                {x.category_name}
                            </td>
                            <td>
                                {x.sub_category_name}
                            </td>
                            <td>
                                {x.quantity}
                            </td>
                            <td>
                                {x.amount}
                            </td>
                            <td>
                                {x.request_date}
                            </td>
                            <td>
                                {(() => {
                                    if (x.req_status == 0) {
                                        return (
                                            <input type="text" name={'Quantity' + x.id} id={'Quantity' + x.id} onChange={this.handleInputChange} className="form-control" placeholder="Enter Qty" required />
                                        )
                                    }
                                    else if (x.req_status == 1) {
                                        return x.approved_quantity
                                    }
                                    else if (x.req_status == 9) {
                                        return '-'
                                    }
                                })()}
                            </td>
                            <td>
                                {(() => {
                                    if (x.req_status == 0) {
                                        //return (
                                        //    <input type="text" name={'Unique_number' + x.id} id={'Unique_number' + x.id} onChange={this.handleInputChange} className="form-control" placeholder="Enter number" required />
                                        //)
                                        return '-'
                                    }
                                    else if (x.req_status == 1) {
                                        return x.unique_mobile_number
                                    }
                                    else if (x.req_status == 9) {
                                        return '-'
                                    }
                                })()}
                            </td>
                            <td>
                                {(() => {
                                    //if (x.req_status == 0) {
                                    //    return (
                                    //        <a className="text-warning font-weight-bold">{this.getStatus(x)}</a>
                                    //    )
                                    //}
                                    if (x.req_status == 1) {
                                        return (
                                            <a className="text-success  font-weight-bold"> <i className="fa fa-circle-check"></i> &nbsp;{this.getStatus(x)}</a>
                                        )
                                    }
                                    else if (x.req_status == 9) {
                                        return (
                                            <a className="text-danger  font-weight-bold"><i className="fa fa-circle-times"></i> &nbsp;{this.getStatus(x)}</a>
                                        )
                                    }
                                })()}
                                {(() => {
                                    if (x.req_status == 0) {
                                        return (
                                            <div>
                                                <a onClick={() => { this.updateDQ(x.id, 1, this.state) }} className="badge badge-success"><i className="fa fa-check"></i>&nbsp; Approve</a>&nbsp;&nbsp;
                                                <a onClick={() => { this.updateDQ(x.id, 9, this.state) }} className="badge badge-danger"><i className="fa fa-times"></i>&nbsp; Reject</a>
                                            </div>
                                        )
                                    }
                                })()}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateDepositRequests();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderDepositRequestsTable(this.state.depositrequests, this.refreshData);
        return (
            
            <div className="row m-0 px-3 mt-3">
                
                <div className="col-xl-12">
                    {/*  <h4 className="my-3">DepositRequests</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Deposit Requests</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Deposits </a></li>
                                <li className="breadcrumb-item active" aria-current="page">Deposit Requests</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="load text-center" style={{ 'display': 'none' }}>
                        <img src="/images/spinner--000.gif" alt="loading icon" width="10%" style={{ 'padding-top': '165px' }} />
                    </div>
                    </div>
                <div className="col-xl-12 tabCustom mb-5 mt-2">
                    <div className="container  px-0 tab-pane active">
                        <div className={this.state.Message ? "alert alert-danger alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                            <strong>
                                {this.state.Message}
                            </strong>
                        </div>
                        <div className="card custom-card">
                            <div className="card-body">
                                {contents}
                            </div>
                        </div>
                    </div>
                
                </div>
              
            </div>
        )
    }
    async populateDepositRequests() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/depositrequests', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            this.setState({ depositrequests: data.data, loading: false });
        }
    }
}

export default DepositRequests;

