import React, { Component, useState } from 'react'
import dateFormat, { masks } from "dateformat";
import { ReactSession } from 'react-client-session';
import 'bootstrap/dist/css/bootstrap.css';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import $ from "jquery";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../root/css/style.css';
import '../root/dashboard/vendors/feather/feather.css';
import '../root/dashboard/vendors/mdi/css/materialdesignicons.min.css';
import '../root/dashboard/vendors/ti-icons/css/themify-icons.css';
import '../root/dashboard/vendors/typicons/typicons.css';
import '../root/dashboard/vendors/simple-line-icons/css/simple-line-icons.css';
import '../root/dashboard/vendors/css/vendor.bundle.base.css';
import '../root/dashboard/js/select.dataTables.min.css';
import '../root/dashboard/css/vertical-layout-light/style.css';
//import '../root/dashboard/css/style.css';
/*import { format } from 'date-fns';*/
import ProgressBar from "@ramonak/react-progress-bar";
import animate from '../root/images/animate-gif.gif';
export class Mining_Requests_Detail extends Component {
    constructor() {
        super();
        this.state = { percentage: "", delivery_Date: "", loading: true, is_delivered: 0 };
    }

    componentDidMount() {
        this.populateCategories();
    }
    refreshData = (data) => {
        this.populateCategories();
    };
    getQueryParams() {
        const search = this.props.location.search.substring(1);
        return new URLSearchParams(search);
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.state.percentage;

        return (
            <div className="row m-0 px-3 mt-3">
                <div class="col-xl-12"><div class="page-header"><div><h1 class="page-title">Mining</h1><ol class="breadcrumb"><li class="breadcrumb-item"><a href="#">Miining</a></li><li class="breadcrumb-item active" aria-current="page">Maining Request Details</li></ol></div></div></div>
                <div className="mt-3">
                    <div className="tabCustom mb-5">
                        <div className="card custom-card text-center mx-auto">
                            <div className="card-body">
                                <div class="wrapper py-4">
                                    {/*<img src={animate} alt="animate gif image" />*/}


                                    <iframe src="https://lottie.host/embed/8b98966c-01fa-4f5f-9445-16c1becad079/DlAr3XB61f.json" width="100%" height="250"></iframe>
                                    {/*<div className={'c100 p' + this.state.percentage + ' blue'}>*/}
                                    {/*    <span>{this.state.percentage}%</span>*/}
                                    {/*    <div class="slice">*/}
                                    {/*        <div class="bar"></div>*/}
                                    {/*        <div class="fill"></div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div class="progress">*/}
                                    {/*    <div class="progress-bar" role="progressbar" style={{ width: "this.state.percentage" }} aria-valuenow="this.state.percentage" aria-valuemin="0" aria-valuemax="100">25%</div>*/}
                                    {/*</div>*/}
                                    <div className="row mt-4 mb-4">
                                        <div className="col-xl-3 mx-auto progressbar">
                                            <ProgressBar completed={this.state.percentage} />
                                    </div>
                                    </div>
                                    {
                                        this.state.percentage === 0
                                            ? "Mining is in progress. Delivery date will be updated soon."
                                            : this.state.delivery_Date <= new Date().toISOString().split('T')[0]
                                                ? this.state.is_delivered === 1
                                                    ? "Data has been sent to mail. Mining has been completed."
                                                    : "Mining has been completed."
                                                : `Mining is in progress and delivery date is ${dateFormat(new Date(this.state.delivery_Date), 'dd-mmm-yyyy')}.`
                                    }
                                </div>
                            
                            </div>
                         
                        </div>
                    </div>
                </div>
            </div>

        )
    }
    async populateCategories() {
        const queryParams = this.getQueryParams();
        const param1Value = queryParams.get('id');
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/Getpercentage?id=' + param1Value, {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            //alert(data.data.is_delivered);
            this.setState({ percentage: data.data.percentage, delivery_Date: data.data.delivery_Date, is_delivered: data.data.is_Delivered, loading: false });
        }
    }
}

export default Mining_Requests_Detail;