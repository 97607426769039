import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import $ from 'jquery';

export class UniqueNumbers extends Component {
    static displayName = UniqueNumbers.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { uploadednumbers: [], categories: [], loading: true, Category_id: "", Uploaded_file: "", isFilePicked: false, Message: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    componentDidMount() {
        this.populateCategories();
        //this.populateSpecialNumbers();
    }

    //renderSpecialNumbersTable(uploadednumbers, refreshData) {
    //    return (
    //        <Table responsive pagination={true} data-show-header="true" data-pagination="true"
    //            data-id-field="name"
    //            data-page-list="[5, 10, 25, 50, 100, ALL]"
    //            data-page-size="10" className="border table-bordered">
    //            <thead className="bg-light-gradient">
    //                <tr>
    //                    <th className="fontSmall fontBold">Number</th>
    //                    <th className="fontSmall fontBold">Status</th>
    //                </tr>
    //            </thead>
    //            <tbody>
    //                {uploadednumbers.map(x =>
    //                    <tr key={x.id}>
    //                        <td>
    //                            {x.number}
    //                        </td>
    //                        <td className="fontBold">
    //                            {x.is_active == 1 ? 'Active' : 'Inactive'}
    //                        </td>
    //                    </tr>
    //                )}
    //            </tbody>
    //        </Table>
    //    );
    //}

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateSpecialNumbers();
    };
    validate() {
        let messageError = "";
        if (!this.state.Uploaded_file) {
            messageError = "File is required";
        }
        if (messageError) {
            this.setState({ Message: messageError });
            return false;
        }
        return true;
    }
    handleSubmit = (e) => {
        $('.load').show();
        e.preventDefault();
        if (this.validate()) {
            const formData = new FormData();
            formData.append('Uploaded_file', this.state.Uploaded_file);
            ReactSession.setStoreType("localStorage");
            var token = ReactSession.get("token");
            try {
                fetch('/specialnumbersapi/postunique', {
                    //headers: {
                    //    'Content-Type': 'multipart/form-data' // <- HERE
                    //},
                    //headers: {
                    //    'Content-Type': 'application/json',
                    //},
                    method: "POST",
                    body: formData,
                    //JSON.stringify({
                    //    Code: e.target.Code.value,
                    //    Name: e.target.Name.value,
                    //    Email: e.target.Email.value,
                    //    Manager_user_id: e.target.Manager.value,
                    //    Country_code: e.target.Country_code.value,
                    //    Time_zone: e.target.Time_zone.value,
                    //}),
                }).then((response) => {
                    return response.json();
                })
                    .then((jsonData) => {
                        if (jsonData.isSuccess) {
                            //this.populateSpecialNumbers();
                            $('.load').hide();
                            this.setState({ Message: "Unique numbers uploaded" });
                            Array.from(document.querySelectorAll("input")).forEach(
                                input => (input.value = ""));
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }
    }
    changeHandler = (e) => {
        console.log('changeHandler');
        console.log(e);
        this.setState({
            Uploaded_file: e.target.files[0],
            isFilePicked: true
        });
    };
    render() {
        //let contents = this.state.loading
        //    ? <p><em>Loading...</em></p>
        //    : this.renderSpecialNumbersTable(this.state.uploadednumbers, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/*    <h4 className="my-3">SpecialNumbers</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Unique Numbers</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Numbers</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Unique Numbers</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="load text-center" style={{ 'display': 'none' }}>
                        <img src="/images/upload-numbers-image.gif" alt="loading icon" width="25%" />
                        <p className="text-center">Uploading data... <br />It will take few seconds. Please sit back.</p>
                    </div>
                    <div className={this.state.Message ? "alert alert-success alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                        <strong>
                            {this.state.Message}
                        </strong>
                    </div>
                    <div className="row row-sm">
                        <div className="col-lg-12">
                            <div className="custom-card">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="card p-4">
                                            <form encType="multipart/form-data" onSubmit={this.handleSubmit}>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <h4 className="pb-4">Upload Numbers Sheet</h4>
                                                    </div>
                                                    <div className="col-sm-12 col-md-12"></div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Upload Unique Numbers Sheet:</label>
                                                            {/*<input type="file" className="form-control" name="file" placeholder="Upload Sheet" accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />*/}
                                                            <input type="file" className="form-control" name="Uploaded_file" id="Uploaded_file" onInput={this.changeHandler} />
                                                            {this.state.isFilePicked ? (
                                                                <div>
                                                                    <p>Filename: {this.state.Uploaded_file.name}</p>
                                                                    <p>Filetype: {this.state.Uploaded_file.type}</p>
                                                                    <p>Size in bytes: {this.state.Uploaded_file.size}</p>
                                                                    <p>
                                                                        lastModifiedDate:{' '}
                                                                        {this.state.Uploaded_file.lastModifiedDate.toLocaleDateString()}
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <p>Select a file to show details</p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-left mt-3">
                                                    <button type="submit" className="btn btn-rounded btn-success">Upload</button>
                                                </div>
                                                <p>&nbsp;</p>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div className="col-lg-12 mt-5">*/}
                        {/*    <div className="card custom-card">*/}
                        {/*        <div className="card-body">*/}
                        {/*            {contents}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        )
    }
    async populateCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ categories: data.data, loading: false });
        }
    }
    async populateSpecialNumbers() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/specialnumbersapi', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ uploadednumbers: data.data, loading: false });
        }
    }
}

export default UniqueNumbers;

