import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";


const SwitchToggle = props => {
    const [checked, setChecked] = useState(props.editRec.is_active == 1 ? true : false);
    const handleChange = (nextChecked) => {
        //console.log(props);
        setChecked(nextChecked);
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/userapi/updatestatus', {
                headers: {
                    'Authorization': 'Basic ' + token,
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: props.editRec.id,
                    status: nextChecked
                }),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="example">
            <label>
                <Switch
                    onChange={handleChange}
                    checked={checked}
                    className={"react-switch"}
                />
            </label>
            <span>{checked}</span>
        </div>
    );
};


export class PurchaseHistory extends Component {
    static displayName = PurchaseHistory.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        /* this.state = { users: [], loading: true, selectedrec: "", usersinactive: [], loading2: true };*/
        this.state = { depositrequests: [], loading: true };

    }

    componentDidMount() {
        this.populatePurchaseHistory();
    }
   
    renderPurchaseHistoryTable(depositrequests, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">Sub Category</th>
                        <th className="fontSmall fontBold">Purchased Date</th>
                        <th className="fontSmall fontBold">Amount</th>
                        <th className="fontSmall fontBold">Payment Status</th>
                        <th className="fontSmall fontBold">Document Link</th>
                        {/*<th className="fontSmall fontBold">Expiry Date</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {this.state.depositrequests.length > 0 ? this.state.depositrequests.map(x =>(
                        <tr>
                            <td>
                                {x.category_name}
                            </td>
                            <td>
                                {x.sub_category_name}
                            </td>
                            <td>
                                {x.request_date}
                            </td>
                            <td>
                                {x.amount}
                            </td>
                            <td>
                                {(() => {
                                    if (x.req_status == 0) {
                                        return (
                                            <strong className="text-warning font-weight-bold">Pending</strong>
                                        )
                                    }
                                    else if (x.req_status == 1) {
                                        return (
                                            <strong className="text-success font-weight-bold">Success</strong>
                                        )
                                    }
                                    else if (x.req_status == 9) {
                                        return (
                                            <strong className="text-danger font-weight-bold">Failed</strong>
                                        )
                                    }
                                })()}
                            </td>
                            <td>
                                {(() => {
                                    if (x.req_status == 1) {
                                        return (
                                            <a className="yBox text-dark" onClick={() => { this.downloadRequest(x.id) }}   download><i className="fa fa-download text-success"></i> &nbsp;Download Data</a>
                                        )
                                    }
                                })()}
                            </td>
                            {/*<td>*/}
                            {/*    {x.request_date}*/}
                            {/*</td>*/}
                        </tr>
                    )) : <tr><td colspan="7"><div className="text-center fontBold"> No Data/Categories are purchased yet. </div></td></tr>}
                </tbody>
            </Table>
        );
    }
    downloadRequest(id) {
        //const newColors = this.state.depositrequests.filter(color => color.id === id);
        //console.log(newColors);
        fetch('category/downloadDocuments?FileName=' + this.state.depositrequests.filter(color => color.id === id)[0].download_link, {
            headers: {
                'Authorization': 'Basic ' + ReactSession.get("token"),
            },
        }).then(res => {
            return res.blob();
        }).then(blob => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', this.state.depositrequests.filter(color => color.id === id)[0].download_link); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => console.error(err));
    }
    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populatePurchaseHistory();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderPurchaseHistoryTable(this.state.depositrequests, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3 mb-5">
                <div className="col-xl-12">
                    {/*<h4 className="my-3">PurchaseHistory</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Purchase History</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">History</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Purchase History</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="container px-0 tab-pane active">
                    <div className="card custom-card">
                        <div className="card-body">
                    {contents}
                </div>
                    </div>
                </div>
            </div>
        )
    }
    async populatePurchaseHistory() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/depositrequestsbyuser', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            //console.log(data.data);
            this.setState({ depositrequests: data.data, loading: false });
        }
    }
}

export default PurchaseHistory;

