import React, { Component, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { ReactSession } from 'react-client-session';
import $ from 'jquery';

export class DataCount extends Component {
    static displayName = DataCount.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { datacount: [], loading: true, Quantity: "" };
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    componentDidMount() {
        this.populateDataCount();
    }
    Deleteuploadeddata(subid) {
        if (window.confirm("Are you sure to delete data")) {
            ReactSession.setStoreType("localStorage");
            var token = ReactSession.get("token");
            try {
                fetch('/category/DeleteCount?subcid=' + subid, {
                    headers: {
                        'Authorization': 'Basic ' + ReactSession.get("token"),
                        'Content-Type': 'application/json',
                    },
                    method: "GET",

                }).then((response) => {
                    this.populateDataCount();
                    return response.json();
                });
            } catch (err) {
                console.log(err);
            }
        }
    }

    renderDataCountTable(datacount, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">S.No</th>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">Sub Category</th>
                        <th className="fontSmall fontBold">Available Data Count</th>
                        <th className="fontSmall fontBold">Actiont</th>
                    </tr>
                </thead>
                <tbody>
                    {datacount.map((x, idx) =>
                        <tr key={x.id}>
                            <td>{idx + 1}</td>
                            <td>
                                {x.categoryname}
                            </td>
                            <td>
                                {x.subcategoryname}
                            </td>
                            <td className="font-weight-bold">
                                {x.totalnumbers}
                            </td>
                            <td>
                                <a href="javascript:;" class="btn btn-danger" onClick={() => this.Deleteuploadeddata(x.sub_category_id)}>Delete</a>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateDataCount();
    };
    render() {
        let contents = this.state.loading
            ? /*<p><em>Loading...</em></p>*/ $(".load").show()
            : this.renderDataCountTable(this.state.datacount, this.refreshData);
        $(".load").hide()
        return (

            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Data Count</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Data </a></li>
                                <li className="breadcrumb-item active" aria-current="page">Data Count</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="col-xl-12 tabCustom mb-5 mt-2">
                    <div className="container  px-0 tab-pane active">
                        <div className={this.state.Message ? "alert alert-danger alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                            <strong>
                                {this.state.Message}
                            </strong>
                        </div>
                        <div className="card custom-card">
                            <div className="card-body">
                                {contents}
                            </div>
                        </div>
                    </div>
                    <div className="load text-center mx-auto" style={{ 'display': 'block' }}>
                        <img src="../images/datacount-loading.gif" alt="loading icon" width="25%" />
                        <p className="text-center">Data loading please wait...</p>
                    </div>
                </div>
            </div>

        )
    }
    async populateDataCount() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/datacount', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            this.setState({ datacount: data.data, loading: false });
        }
    }
}

export default DataCount;

