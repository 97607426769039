import React, { Component, useState } from 'react'
import { ReactSession } from 'react-client-session';
import 'bootstrap/dist/css/bootstrap.css';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import $ from "jquery";
import { NavLink } from 'reactstrap';
import dateFormat, { masks } from "dateformat";
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../root/css/style.css';
import '../root/dashboard/vendors/feather/feather.css';
import '../root/dashboard/vendors/mdi/css/materialdesignicons.min.css';
import '../root/dashboard/vendors/ti-icons/css/themify-icons.css';
import '../root/dashboard/vendors/typicons/typicons.css';
import '../root/dashboard/vendors/simple-line-icons/css/simple-line-icons.css';
import '../root/dashboard/vendors/css/vendor.bundle.base.css';
import '../root/dashboard/js/select.dataTables.min.css';
import '../root/dashboard/css/vertical-layout-light/style.css';
import '../root/dashboard/css/style.css';
//import { format } from 'date-fns';


const SwitchToggle = props => {
    const [checked, setChecked] = useState(props.editRec.is_Delivered == 1 ? true : false);

    const handleChange = (nextChecked) => {
        console.log(props);
        setChecked(nextChecked);
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/category/updatestatusmining', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: props.editRec.id,
                    status: nextChecked
                }),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="example">
            <label>
                <Switch
                    onChange={handleChange}
                    checked={checked}
                    className={"react-switch"}
                />
            </label>
            <span>{checked}</span>
        </div>
    );
};
const today = new Date().toISOString().split('T')[0]; // Get today's date in "yyyy-mm-dd" format

export class Mining_Requests extends Component {
    constructor() {
        super();
        this.state = { categories: [], loading: true, selectedrec: "", Url: "",No_Of_Leads:"",Email:"" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    Updatedeliverydate = (event, id) => {
        const selectedDate = event.target.value;
        try {
            fetch('/category/updateminingdeliverydate', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: id,
                    date: selectedDate
                }),
            }).then((response) => {
                this.setState({ Message: response.message });
                this.populateCategories();
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );
                //return response.json();
            })
                .then((data) => {
                    //props.callbackFn(data);
                    //this.setState({ Message: data.message });
                    console.log();
                });
        } catch (err) {
            console.log(err);
        }
        //alert(selectedDate);

    }
    componentDidMount() {
        this.populateCategories();
    }
    refreshData = (data) => {
        this.populateCategories();
    };
    renderCategoriesTable(categories, refreshData) {
        return (
            <Table datatable responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">URL</th>
                        <th className="fontSmall fontBold">Email ID</th>

                        <th className="fontSmall fontBold">Leads</th>
                        <th className="fontSmall fontBold">Requested On</th>
                        {/*<th className="fontSmall fontBold">Delivery Date</th>*/}
                        <th className="fontSmall fontBold">Update Delivery Date</th>
                        <th className="fontSmall fontBold">Delivery Status</th>
                    </tr>
                </thead>
                <tbody>

                    {categories.map(cat =>

                        <tr key={cat.id}>

                            <td>
                                {cat[0].url}
                            </td>
                            <td>
                                {cat[0].email_ID}
                            </td>
                            <td>
                                {cat[0].requested_Leads}
                            </td>
                            <td>
                                {cat[0].request_Date}
                            </td>
                            {/*<td>*/}
                            {/*    {cat[0].delivery_Date == null ? ("Will be updated soon") : (cat[0].delivery_Date)}*/}
                            {/*</td>*/}
                            
                            <td className="fontBold">
                                <input type="date" min={today} value={cat[0].delivery_Date != null ? dateFormat(new Date(cat[0].delivery_Date), 'yyyy-mm-dd') : ''} name="Delivery_Date" onChange={(e) => this.Updatedeliverydate(e, cat[0].id)}
                                    onKeyDown={(e) => e.preventDefault()} // Disable keyboard input
                                    onKeyPress={(e) => e.preventDefault()} // Disable keyboard input
                                    onKeyUp={(e) => e.preventDefault()} // Disable keyboard input
                                    onPaste={(e) => e.preventDefault()} // Disable pasting
                                ></input>
                                {/*<NavLink className="nav-link" tag={Link} to={`/user/mining_requests_detail?id=${cat[0].mining_Id}`} ><span className="menu-title ps-3">View</span></NavLink>*/}
                            </td>
                            <td>
                                {cat[0].is_Delivered == 1 ? <SwitchToggle callbackFn={refreshData}  editRec={cat[0]} /> : <SwitchToggle callbackFn={refreshData}  editRec={cat[0]} />}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }
    handleSubmit = (e) => {
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/category/postmining', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Url: this.state.Url,
                    Requested_Leads: this.state.No_Of_Leads,
                    Email_ID:this.state.Email
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    debugger;
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.setState({ Message: jsonData.message });
                        this.populateCategories();
                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
   
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderCategoriesTable(this.state.categories, this.refreshData);
        return (
            


            <div className="row m-0 p-3">
                <div className="col-xl-12 my-2">
                    <div className="page-header">
                     
                        <div>
                            <h1 className="page-title">Request Leads</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Super Admin</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Request Leads</li>
                            </ol>
                        </div>
                    </div>
                    {/*<h4 className="fontBold">Create Category</h4>*/}
                    {/*<a className="f-12 text-decoration-none">Category</a> <span className="f-12 mx-2">/</span><a className="f-12 text-primary text-decoration-none">Create Category</a>*/}
                </div>
                <div className="col-lg-12">
                    <div className={this.state.Message ? "alert alert-success alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                        <strong>
                            {this.state.Message}
                        </strong>
                    </div>
                </div>
                <div className="">
                    <div className="">
                        <div className="tabCustom mb-5">
                            <div className="card custom-card">
                                <div className="card-body">
                                    {contents}
                                </div>
                            </div></div>
                    </div>
                </div>

            </div>
        )
    }
    async populateCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/getadminminingrequets', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ categories: data.data, loading: false });
        }
    }
}

export default Mining_Requests;