import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import axios from 'axios'

import logo from '../gdp/root/images/logo/logo.png';
import '../root/dashboard/vendors/mdi/css/materialdesignicons.min.css';

export class Login extends Component {
    static displayName = Login.name;
    constructor(props) {
        super(props);
        this.state = { Email: "", Password: "", Message: "", Ip: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        console.log(res.data);
        this.setState
            ({
                Ip: res.data.IPv4,
            });

    }
    componentDidMount() {
        //this.getData();
        $('body').attr('class', 'page login-img');
        console.log(localStorage);
        localStorage.clear();
        console.log(localStorage);
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    validate() {
        let messageError = "";
        if (!this.state.Email) {
            messageError = "Email is required";
        }
        else if (!this.state.Password) {
            messageError = "Password is required";
        }
        if (messageError) {
            this.setState({ Message: messageError });
            return false;
        }
        return true;
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if (this.validate()) {
            try {
                fetch('/login/post', {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    method: "POST",
                    body: JSON.stringify({
                        Email: this.state.Email,
                        Password: this.state.Password,
                        Ip: ""
                    }),
                }).then((response) => {
                    return response.json();
                })
                    .then((jsonData) => {
                        if (jsonData.isSuccess) {
                            //alert(jsonData.data.email);
                            ReactSession.setStoreType("localStorage");
                            ReactSession.set("login_datetime", new Date());
                            ReactSession.set("user_id", jsonData.data.id);
                            ReactSession.set("session_user", jsonData.data);
                            ReactSession.set("token", jsonData.data.token);
                            ReactSession.set("username", jsonData.data.username);
                            ReactSession.set("user_role", jsonData.data.user_role_name);
                            ReactSession.set("email", jsonData.data.email);

                            
                            if (jsonData.data.user_role_id == 1) {
                               /* if (this.state.Ip != undefined && this.state.Ip != "") {*/
                                    this.props.history.push("/superadmin");
                                //}
                                //else {
                                //    this.setState({ Message: "Unable to fetch IP. Please reload and try again." });
                                //}
                            }
                            else if (jsonData.data.user_role_id == 2)
                                this.props.history.push("/admin");
                            else if (jsonData.data.user_role_id == 3)
                                this.props.history.push("/seller");
                            else if (jsonData.data.user_role_id == 4)
                                this.props.history.push("/user");
                        }
                        else {
                            this.setState({ Message: jsonData.message });
                        }
                    });
            } catch (err) {
                console.log(err);
            }
        }


    }
    render() {
        return (
            <div>
                <div className="container-login100 col-12">
                    <div className="wrap-login100 p-0">
                        <div className="card-body">
                            <form className="login100-form validate-form" onSubmit={this.handleSubmit}>
                                <div className="text-center">
                                   <img src={logo} alt="logo image" className="img-responsive mx-auto" width="150" />
                                </div>
                                <span className="mt-4 mb-4 text-left d-block"> Please enter the credentials to login </span>
                                <div className="wrap-input100 validate-input inputs">
                                    <input className="input100" type="text" name="Email" id="Email" onChange={this.handleInputChange} autoComplete="off" placeholder=" " />
                                    <label>Enter Email ID</label>
                                    <span className="symbol-input100"> <i className="zmdi zmdi-email" aria-hidden="true"></i> </span>
                                </div>
                                <div className="wrap-input100 validate-input inputs mt-4">
                                    <input className="input100" type="password" name="Password" id="Password" onChange={this.handleInputChange} autoComplete="off" placeholder=" " />  <label>Enter Password</label>
                                    <span className="focus-input100"></span> <span className="symbol-input100"> <i className="zmdi zmdi-lock" aria-hidden="true"></i> </span>
                                </div>
                                <div className="text-end pt-1">
                                    <p className="mb-0">
                                        {/*<NavLink className="hypLink" tag={Link} to="/changepassword">*/}
                                        {/*    {i18next.t("ForgotPassword")}*/}
                                        {/*</NavLink>*/}
                                        {/*  <a href="forgot-password.html" className="text-primary ms-1">Forgot Password?</a>*/}
                                    </p>
                                </div>
                                <div className="container-login100-form-btn">
                                    <button type="submit" className="login100-form-btn btn-warning"> Login </button>
                                    <div className="message pt-2">{this.state.Message ? <h6 className="text-danger pt-2">{this.state.Message}</h6> : null}</div>
                                </div>
                            </form>

                        </div>

                    </div>
                    <div className="col-12"></div>
                    <div className="col-3 mx-auto text-center pt-2">
                        <span className="text-white">Registration for </span>
                        {/*<NavLink className="text-center text-white p-1 d-inline-block" tag={Link} to="/register">*/}
                        {/*  <span className="h5">Seller</span>*/}
                        {/*    </NavLink>*/}
                        {/*   <span className="d-inline-block text-white">/</span>*/}
                        <NavLink className="text-center text-white p-1 d-inline-block" tag={Link} to="/userregistration">
                            <span className="h5">User</span>
                        </NavLink>
                        {/*<a href="registration.html" className="text-center col-12 text-white pt-2">*/}
                        {/*    Registration for <span className="h4">Seller</span>*/}
                        {/*</a>*/}
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;