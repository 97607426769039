import React, { Component, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";


const SwitchToggle = props => {
    const [checked, setChecked] = useState(props.editRec.is_active == 1 ? true : false);
    const handleChange = (nextChecked) => {
        console.log(props);
        setChecked(nextChecked);
        try {
            fetch('/userapi/updatestatus', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: props.editRec.id,
                    status: nextChecked
                }),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="example">
            <label>
                <Switch
                    onChange={handleChange}
                    checked={checked}
                    className={"react-switch"}
                />
            </label>
            <span>{checked}</span>
        </div>
    );
};

export class Users extends Component {
    static displayName = Users.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { users: [], loading: true, selectedrec: "", usersinactive: [], loading2: true };

    }

    componentDidMount() {
        this.populateUsers();
        this.populateInactiveUsers();
    }

    //userDelete(userId) {
    //    alert(userId);
    //    var person = { "Id": "" + userId + "" };
    //    try {
    //        fetch('/userapi/delete', {
    //            headers: {
    //                'Content-Type': 'application/json',
    //            },
    //            method: "POST",
    //           // data:  person,
    //            body: JSON.stringify(person),
    //        }).then((response) => {
    //            return response.json();
    //        })
    //            .then((data) => {
    //                this.populateUsers();
    //               // console.log(props);
    //                //props.callbackFn(data);
    //            });
    //    } catch (err) {
    //        console.log(err);
    //    }}

    renderUsersTable(users, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">Name</th>
                        <th className="fontSmall fontBold">Phone</th>
                        <th className="fontSmall fontBold">Email</th>
                        <th className="fontSmall fontBold">Status</th>
                        <th className="fontSmall fontBold">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user =>
                        <tr key={user.id}>
                            <td>
                                {user.name}
                            </td>
                            <td>
                                {user.mobile_number}
                            </td>
                            <td>
                                {user.email}
                            </td>
                            <td className="fontBold">
                                {user.is_active == 1 ? 'Active' : 'Inactive'}
                            </td>
                            <td>
                                <SwitchToggle callbackFn={refreshData} editRec={user} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateUsers();
        this.populateInactiveUsers();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderUsersTable(this.state.users, this.refreshData);

        let contents2 = this.state.loading2
            ? <p><em>Loading...</em></p>
            : this.renderUsersTable(this.state.usersinactive, this.refreshData);
        const options = {
            expandRowBgColor: 'rgb(173,216,230)',
            page: 1,  // page that shows as default
            sizePerPageList: [{
                text: '5', value: 5
            }, {
                text: '10', value: 10
            }, {
                text: '25', value: 25
            }, {
                text: '50', value: 50
            }], // you can change the dropdown list for size per page
            sizePerPage: 10,  // which size per page you want to locate as default
            pageStartIndex: 1, // where to start counting the pages
            paginationSize: 3,  // the pagination bar size.
            prePage: '<', // Previous page button text
            nextPage: '>', // Next page button text
            firstPage: '<<', // First page button text
            lastPage: '>>', // Last page button text
            paginationShowsTotal: this.renderShowsTotal,  // Accept bool or function
            paginationPosition: 'bottom'  // default is bottom, top and both is all available
            // hideSizePerPage: true > You can hide the dropdown for sizePerPage
            // alwaysShowAllBtns: true // Always show next and previous button
            // withFirstAndLast: false > Hide the going to First and Last page button
        };
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/* <h4 className="my-3">Users</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Users</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Users</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Users List</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 tabCustom mb-5">
                    <div className="card custom-card">
                        <div className="card-body">
                            <ul className="nav border px-4" role="tablist">
                                <li className="nav-item text-start">
                                    <a className="nav-link active tabList py-3" data-bs-toggle="tab" href="#teamMembers">Active</a>
                                </li>
                                <li className="nav-item text-start">
                                    <a className="nav-link tabList py-3" data-bs-toggle="tab" href="#inActiveTeamMembers">Inactive</a>
                                </li>
                                <li className="border-0">
                                    <div className="tabAddMember mt-2">
                                    </div>
                                </li>

                            </ul>
                            <div className="tab-content px-4 shadow pt-2 overflow-auto">
                                <div id="teamMembers" className="container-fluid px-0 tab-pane active">
                                    {contents}
                                </div>
                                <div id="inActiveTeamMembers" className="container-fluid px-0 tab-pane fade">
                                    {contents2}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateUsers() {
        const response = await fetch('/userapi');
        const data = await response.json();
        console.log(data.data);
        this.setState({ users: data.data, loading: false });
    }
    async populateInactiveUsers() {
        const response = await fetch('/userapi/inactive');
        const data = await response.json();
        console.log(data);
        this.setState({ usersinactive: data.data, loading2: false });
    }
}

export default Users;

