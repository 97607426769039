import React, { Component, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../root/css/style.css';
import '../root/dashboard/vendors/feather/feather.css';
import '../root/dashboard/vendors/mdi/css/materialdesignicons.min.css';
import '../root/dashboard/vendors/ti-icons/css/themify-icons.css';
import '../root/dashboard/vendors/typicons/typicons.css';
import '../root/dashboard/vendors/simple-line-icons/css/simple-line-icons.css';
import '../root/dashboard/vendors/css/vendor.bundle.base.css';
import '../root/dashboard/js/select.dataTables.min.css';
import '../root/dashboard/css/vertical-layout-light/style.css';
import '../root/dashboard/css/style.css';

const SwitchToggle = props => {
    const [checked, setChecked] = useState(props.editRec.is_active == 1 ? true : false);
    const handleChange = (nextChecked) => {
        console.log(props);
        setChecked(nextChecked);
        try {
            fetch('/category/updatestatus', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: props.editRec.id,
                    status: nextChecked
                }),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="example">
            <label>
                <Switch
                    onChange={handleChange}
                    checked={checked}
                    className={"react-switch"}
                />
            </label>
            <span>{checked}</span>
        </div>
    );
};

export class CreateCategory extends Component {
    constructor() {
        super();
        this.state = { categories: [], loading: true, selectedrec: "", Category_name: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    componentDidMount() {
        this.populateCategories();
    }
    refreshData = (data) => {
        this.populateCategories();
    };
    renderCategoriesTable(categories, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">Sub Categories</th>
                        <th className="fontSmall fontBold">Status</th>
                        <th className="fontSmall fontBold">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map(cat =>
                        <tr key={cat[0].id}>
                            <td>
                                {cat[0].name}
                            </td>
                            <td>
                                <ul>
                                    {cat.map(sub =>
                                        <li>{sub.sub_category_name}</li>
                                    )}
                                </ul>
                            </td>
                            <td className="fontBold">
                                {cat[0].is_active == 1 ? 'Active' : 'Inactive'}
                            </td>
                            <td>
                                <SwitchToggle callbackFn={refreshData} editRec={cat[0]} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }
    handleSubmit = (e) => {
        e.preventDefault();
        try {
            fetch('/category/post', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Name: this.state.Category_name,
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.populateCategories();
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    handleSubmitSubCategory = (e) => {
        e.preventDefault();
        try {
            fetch('/category/postsub', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Category_id: this.state.Category_id,
                    Name: this.state.Subcategory_name,
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.populateCategories();
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderCategoriesTable(this.state.categories, this.refreshData);
        return (
            <div className="row m-0 p-3">
                <div className="col-xl-12 my-2">
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Create Category</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Category</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Create Category</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-10 col-md-10">
                                    <div className="form-group">
                                        <label className="form-label mb-2">Category Name:</label>
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <input className="form-control" name="Category_name" id="Category_name" onChange={this.handleInputChange} placeholder="Enter Category Name" type="text" />
                                                </div>
                                                <div className="col-sm-2 col-md-2">
                                                    <button className="btn btn-success" type="submit">+ Add</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-12"></div>
                                <div className="col-sm-12">
                                    <form onSubmit={this.handleSubmitSubCategory}>
                                        <div className="row">
                                            <div className="form-group">
                                                <label className="form-label mb-2">Sub Category Name:</label>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <select name="Category_id" id="Category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                            <option value="">Select</option>
                                                            {this.state.categories.map(cat =>
                                                                <option value={cat[0].id}>{cat[0].name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                    <div className="form-group">
                                                        <input type="text" placeholder="Add Sub Category" name="Subcategory_name" id="Subcategory_name" onChange={this.handleInputChange} className="required-entry form-control" />
                                                    </div>
                                                    <button className="btn btn-success" type="submit">+ Add Sub Category</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-12">
                    <div className="mt-3">
                        <div className="tabCustom mb-5">
                            <div className="card custom-card">
                                <div className="card-body">
                                    {contents}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
    async populateCategories() {
        const response = await fetch('/category');
        const data = await response.json();
        console.log(data.data);
        this.setState({ categories: data.data, loading: false });
    }
}

export default CreateCategory;