import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import $ from 'jquery';


export class Wallet extends Component {
    static displayName = Wallet.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { userwallet: [], loading: true, selectedrec: "", usersinactive: [], loading2: true };

    }

    componentDidMount() {
        this.populateWallet();
    }

    renderWalletTable(userwallet, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">Trans Type</th>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">Credit (+)</th>
                        <th className="fontSmall fontBold">Debit (-)</th>
                        <th className="fontSmall fontBold">Date</th>
                        <th className="fontSmall fontBold">Balance</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.userwallet.length > 0 ? this.state.userwallet.map(user => (
                        <tr key={user.id}>
                            <td>{user.trans_type == 1 ? "Credit" : "Debit"}</td>
                            <td>{user.category_id == 0 ? "-" : user.category_name}</td>
                            <td>{user.trans_type == 1 ? user.trans_amount : "-"}</td>
                            <td>{user.trans_type == 2 ? user.trans_amount : "-"}</td>
                            <td>{user.created_datetime}</td>
                            <td>{user.balance}</td>
                        </tr>
                    )) : <tr><td colspan="6"><div className="text-center fontBold"> No Statements are listed.</div></td></tr>}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateWallet();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderWalletTable(this.state.userwallet, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/*<h4 className="my-3">Wallet</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Wallet</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Wallet</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Wallet</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="container px-0 tab-pane active mb-5">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="col-xl-12 text-center">
                                <p className="space">&nbsp;</p>
                                <img src="../images/wallet.png" alt="wallet icon" width="100" />
                                <h4 className="pt-4">Available Amount
                                    <div className="text-success h2 font-weight-bold mt-3 d-block">{this.state.loading ? 0 : (this.state.userwallet.length == 0 ? 0 : this.state.userwallet[0].balance)} <span className="h5 text-warning d-none">(5 Points)</span></div>
                                </h4>
                            </div>
                            <div class="col-xl-12 mt-5 mb-5">
                                <h3>Wallet Statements List</h3>
                            </div>
                            {contents}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateWallet() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/userwalletapi', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ userwallet: data.data, loading: false });
        }
    }
}

export default Wallet;

