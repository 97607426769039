import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import $ from 'jquery';


const SwitchToggle = props => {
    const [checked, setChecked] = useState(props.editRec.is_active == 1 ? true : false);
    const handleChange = (nextChecked) => {
        console.log(props);
        setChecked(nextChecked);
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/userapi/updatestatus', {
                headers: {
                    'Authorization': 'Basic ' + token,
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: props.editRec.id,
                    status: nextChecked
                }),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="example">
            <label>
                <Switch
                    onChange={handleChange}
                    checked={checked}
                    className={"react-switch"}
                />
            </label>
            <span>{checked}</span>
        </div>
    );
};

export class AssignCategoryPrice extends Component {
    static displayName = AssignCategoryPrice.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { AssignedCategoryPrices: [], loading: true, categories: [], subcategories: [], Category_id: "", Sub_category_id: "", Quantity: "", Price: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.Category_id = "";
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        if (name == 'Category_id') {
            this.Category_id = value;
            let subcategories = this.state.categories.filter(
                (cats) => { if (cats[0].id == value) return cats; }
            );
            this.setState({
                subcategories: subcategories[0],
            });
        }
    }

    componentDidMount() {
        this.populateCategories();
        this.populateAssignCategoryPrice();
    }

    renderAssignCategoryPriceTable(AssignedCategoryPrices, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">Category</th>
                        <th className="fontSmall fontBold">Sub Category</th>
                        <th className="fontSmall fontBold">Quantity</th>
                        <th className="fontSmall fontBold">Price</th>
                     {/*   <th className="fontSmall fontBold">Status</th>*/}
                        {/*<th className="fontSmall fontBold">Action</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {AssignedCategoryPrices.map(user =>
                        <tr key={user.id}>
                            <td>
                                {user.category_name}
                            </td>
                            <td>
                                {user.sub_category_name}
                            </td>
                            <td>
                                {user.quantity}
                            </td>
                            <td>
                                {user.price}
                            </td>
                            {/*<td className="fontBold">*/}
                            {/*    {user.is_active == 1 ? 'Active' : 'Inactive'}*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*    <SwitchToggle callbackFn={refreshData} editRec={user} />*/}
                            {/*</td>*/}
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateAssignCategoryPrice();
    };
    handleSubmit = (e) => {
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/category/assignprice', {
                headers: {
                    'Authorization': 'Basic ' + token,
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Category_id: this.state.Category_id,
                    Sub_category_id: this.state.Sub_category_id,
                    Quantity: this.state.Quantity,
                    Price: this.state.Price,
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.setState({ Message: jsonData.message });
                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );
                        this.populateAssignCategoryPrice();
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderAssignCategoryPriceTable(this.state.AssignedCategoryPrices, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/*<h4 className="my-3">AssignCategoryPrice</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Assign Category Price</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Assign Category </a></li>
                                <li className="breadcrumb-item active" aria-current="page">Assign Category Price</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="row row-sm">
                        <div className="col-lg-12">
                            <div className={this.state.Message ? "alert alert-success alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                                <strong>
                                    {this.state.Message}
                                </strong>
                            </div>
                            <div className="card custom-card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Category:</label>
                                                    <div className="input-group">
                                                        <select name="Category_id" id="Category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                            <option value="">Select</option>
                                                            {this.state.categories.map(cat =>
                                                                <option value={cat[0].id}>{cat[0].name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Sub Category:</label>
                                                    <div className="input-group">
                                                        <select name="Sub_category_id" id="Sub_category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                            <option value="">Select</option>
                                                            {this.state.subcategories.map(subcat =>
                                                                <option value={subcat.sub_category_id}>{subcat.sub_category_name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Quantity:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" autoComplete="off" name="Quantity" id="Quantity" onChange={this.handleInputChange} placeholder="Enter Quantity" type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Price:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" autoComplete="off" name="Price" id="Price" onChange={this.handleInputChange} placeholder="Enter Price" type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-left col-12">
                                            <button type="submit" className="btn btn-rounded btn-primary">Submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 mt-4">
                    <div className="col-12">
                        <div className="card custom-card mb-5">
                            <div className="card-body">
                                {contents}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        console.log(data.data);
        this.setState({ categories: data.data, loading: false });
    }
    async populateAssignCategoryPrice() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/assignedprices', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status == '401') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ AssignedCategoryPrices: data.data, loading: false });
        }
    }
}

export default AssignCategoryPrice;

