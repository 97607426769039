import React from 'react'
import PropTypes from 'prop-types';
import Image from 'react-bootstrap/Image';
import { ReactSession } from 'react-client-session';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/free-regular-svg-icons'

export class Dashboard extends React.Component {
    static displayName = Dashboard.name;
    constructor() {
        super();
        this.state = { categories: [], userwallet: [], depositrequests: [], loading: true, subcategories: [], Category_id: "", Sub_category_id: "", SampleRequest: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.Category_id = "";
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        if (name == 'Category_id') {
            this.Category_id = value;
            console.log(this.state.categories);
            let subcategories = this.state.categories.filter(
                (cats) => { if (cats[0].id == value) return cats; }
            );
            console.log(value);
            console.log(subcategories);
            this.setState({
                subcategories: subcategories[0],
            });
        }
    }
    componentDidMount() {
        this.populateSampleRequest();
        this.populatePurchaseHistory();
        this.populateWallet();
    }


    toggleMenuState(menuState) {
        if (this.state[menuState]) {
            this.setState({ [menuState]: false });
        } else if (Object.keys(this.state).length === 0) {
            this.setState({ [menuState]: true });
        } else {
            Object.keys(this.state).forEach(i => {
                this.setState({ [i]: false });
            });
            this.setState({ [menuState]: true });
        }
    }

    toggleOffcanvas() {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    }
    toggleRightSidebar() {
        document.querySelector('.right-sidebar').classList.toggle('open');
    }
    signOutClick() {
        localStorage.clear();
        window.location.href = "/"
    }

    state = {
        modal: false
    }

    selectModal = (info) => {
        this.setState({ modal: !this.state.modal });
    }

    sendRequestForm = () => {
        console.log('send');
        $('.sentr').show();
        this.populateCategories();
    }
    downloadSampleRequest = () => {
        console.log(this.state.SampleRequest.download_link);
        fetch('category/downloadsamplerequest?FileName=' + this.state.SampleRequest.download_link, {
            headers: {
                'Authorization': 'Basic ' + ReactSession.get("token"),
            },
        }).then(res => {
            return res.blob();
        }).then(blob => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', this.state.SampleRequest.download_link); //or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => console.error(err));
    }
    handleSubmit = (e) => {
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/category/samplerequest', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Category_id: this.state.Category_id,
                    Sub_category_id: this.state.Sub_category_id,
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        $('.scat, .sreq').hide();
                        $('.alert-success').show();
                        $('.sentr').show();
                        $('.float').show();
                        window.location.reload();
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        const { match } = this.props
        return (
            <div className="side-app mt-5">
                {/*<div className="alert alert-success alert-dismissible text-center" style={{ marginTop: '18px' }}>*/}
                {/*    <a href="#" className="close" data-dismiss="alert" aria-label="close" title="close">�</a>*/}
                {/*    <strong>*/}
                {/*        Sample request for the database is successfully sent.*/}
                {/*    </strong>*/}
                {/*</div>*/}
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4">
                                <div className="card overflow-hidden text-dark">
                                    <div className="card-body pb-2">
                                        <div className="row">
                                            <div className="col">
                                                <h6>Wallet</h6>
                                                <h3 className="number-font">{this.state.loading ? 0 : (this.state.userwallet.length == 0 ? 0 : this.state.userwallet[0].balance)} </h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-primary-gradient box-shadow-primary brround ms-auto">
                                                    <i className="icon icon-wallet text-white mb-5"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4">
                                <div className="card overflow-hidden text-dark">
                                    <div className="card-body pb-2">
                                        <div className="row">
                                            <div className="col">
                                                <h6>Purchased</h6>
                                                <h3 className="number-font">{this.state.loading ? 0 : this.state.depositrequests.length}</h3>
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-danger-gradient box-shadow-danger brround ms-auto"><i className="icon fa fa-database text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12 col-xl-4 position-relative">
                                <div className="float blink" style={{ display: 'none' }}>You will receive 1000 numbers</div>
                                <div className="card overflow-hidden">
                                    <div className="card-body pb-2">
                                        <div className="row">
                                            <div className="col">
                                                <h6>Sample Data Request</h6>
                                                {(() => {
                                                    if (this.state.SampleRequest == null) {
                                                        return (
                                                            <a style={{ cursor: 'pointer' }} onClick={this.sendRequestForm} className="badge bg-primary badge-primary text-white sreq">Send Request <i className="fa fa-paper-plane"></i></a>
                                                        )
                                                    }
                                                    else if (this.state.SampleRequest.req_status == 0) {
                                                        return (
                                                            <a style={{ cursor: 'pointer' }} className="badge bg-warning badge-warning sreq">Request Pending</a>
                                                        )
                                                    }
                                                    else if (this.state.SampleRequest.req_status == 1) {
                                                        return (
                                                            <div>
                                                                <a className="yBox text-dark" onClick={this.downloadSampleRequest}><i className="fa fa-download text-success"></i> &nbsp;Download Data</a>
                                                                {/*href={"downloadSampleDocuments/" + this.state.SampleRequest.download_link} download*/}
                                                            </div>
                                                        )
                                                    }
                                                    else if (this.state.SampleRequest.req_status == 9) {
                                                        return (
                                                            <a style={{ cursor: 'pointer' }} className="badge bg-danger badge-danger sreq">Request Rejected <i className="fa fa-paper-plane"></i></a>
                                                        )
                                                    }
                                                })()}
                                                {/*<a className="d-block text-dark sentr pt-1 ml-0 h6" style={{ display: 'none' }} disabled> <i className="fa fa-check-circle-o"></i> Request Sent</a>*/}
                                            </div>
                                            <div className="col col-auto">
                                                <div className="counter-icon bg-success-gradient box-shadow-success brround ms-auto"><i className="fa fa-briefcase text-white mb-5"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row sentr mt-4" style={{ display: 'none' }}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xl-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card overflow-hidden">
                                    <div className="card-body pb-2">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-5">
                                                                <label className="form-label">Select Category:</label>
                                                            </div>
                                                            <div className="col-lg-7">
                                                                <div className="input-group">
                                                                    <select name="Category_id" id="Category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                                        <option value="">Select</option>
                                                                        {this.state.categories.map(cat =>
                                                                            <option key={cat[0].id} value={cat[0].id}>{cat[0].name}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 form-group"></div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-5">
                                                                <label className="form-label">Select Sub Category:</label>
                                                            </div>
                                                            <div className="col-lg-7">
                                                                <div className="input-group">
                                                                    <select name="Sub_category_id" id="Sub_category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                                        <option value="">Select</option>
                                                                        {this.state.subcategories.map(subcat =>
                                                                            <option value={subcat.sub_category_id}>{subcat.sub_category_name}</option>
                                                                        )}
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12"></div>
                                            <div className="col-sm-6 col-md-6 mt-3">
                                                <div className="form-group">
                                                    <div className="row">
                                                        <div className="col-lg-5">
                                                            <label className="form-label">&nbsp;</label>
                                                        </div>
                                                        <div className="col-lg-7">
                                                            <div className="input-group">
                                                                <button type="submit" className="btn btn-rounded btn-primary">Send Request</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        console.log(data.data);
        this.setState({ categories: data.data, loading: false });
    }
    async populateSampleRequest() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/samplerequestbyuser', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        console.log(data.data);
        this.setState({ SampleRequest: data.data, loading: false });
    }
    async populateWallet() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/userwalletapi', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        })
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ userwallet: data.data, loading: false });
        }
    }

    async populatePurchaseHistory() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category/depositrequestsbyuser', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        console.log(data.data);
        this.setState({ depositrequests: data.data, loading: false });
    }
}


export default Dashboard