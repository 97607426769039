import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const SwitchToggle = props => {
    const [checked, setChecked] = useState(props.editRec.is_active == 1 ? true : false);
    const handleChange = (nextChecked) => {
        console.log(props);
        setChecked(nextChecked);
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/userapi/updatestatus', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: props.editRec.id,
                    status: nextChecked
                }),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="example">
            <label>
                <Switch
                    onChange={handleChange}
                    checked={checked}
                    className={"react-switch"}
                />
            </label>
            <span>{checked}</span>
        </div>
    );
};

export class Clients extends Component {
    static displayName = Clients.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { users: [], loading: true, selectedrec: "", Phone: "", Email: "", categories: [], subcategories: [], Category_id: "", Sub_category_id: "", FromDate: "", ToDate: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.Category_id = "";
    }
   

    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        if (name == 'Category_id') {
            this.Category_id = value;
            let subcategories = this.state.categories.filter(
                (cats) => { if (cats[0].id == value) return cats; }
            );
            this.setState({
                subcategories: subcategories[0],
            });
        }
    }
    componentDidMount() {
        this.populateCategories();
        this.populateUsers();
    }
    handleSubmit = (e) => {
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/userapi/search', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Phone: this.state.Phone,
                    Email: this.state.Email,
                    Category: this.state.Category,
                    SubCategory: this.state.SubCategory,
                    FromDate: this.state.FromDate,
                    ToDate: this.state.ToDate,
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.setState({ users: jsonData.data, loading: false });
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    renderUsersTable(users, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">S.No.</th>
                        <th className="fontSmall fontBold">Name</th>
                        <th className="fontSmall fontBold">Phone Number</th>
                        <th className="fontSmall fontBold">Email</th>
                        <th className="fontSmall fontBold">Password</th>

                        <th className="fontSmall fontBold">Recent Purchase</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user,idx) =>
                        <tr key={user.id}>
                            <td className="fontBold">
                                {idx +1}
                            </td>
                            <td className="fontBold">
                                {user.name}
                            </td>
                            <td className="fontBold">
                                {user.mobile_number}
                            </td>
                            <td className="fontBold">
                                {user.email}
                            </td>
                            <td className="fontBold">
                                {user.password}
                            </td>
                            
                            <td className="fontBold">{user.category_name}</td>
                        
                            {/*<td className="fontBold">*/}
                            {/*    {user.is_active == 1 ? 'Active' : 'Inactive'}*/}
                            {/*</td>*/}
                            {/*<td>*/}
                            {/*    <SwitchToggle callbackFn={refreshData} editRec={user} />*/}
                            {/*</td>*/}
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateUsers();
        this.populateInactiveUsers();
    };
   
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderUsersTable(this.state.users, this.refreshData);

        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/*  <h4 className="my-3">Users</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Client's List</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Client's</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Client's List</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="row row-sm">
                        <div className="col-lg-12">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Mobile Number:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" autoComplete="off" name="Phone" id="Phone" onChange={this.handleInputChange} placeholder="Enter Mobile Number" type="text" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Email Address:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" autoComplete="off" name="Email" onChange={this.handleInputChange} placeholder="Enter Email Address" type="email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Category:</label>
                                                    <div className="input-group">
                                                        {/*<input className="form-control" name="Category" onChange={this.handleInputChange} placeholder="Enter Category" type="text" />*/}
                                                        <select name="Category_id" id="Category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                            <option value="">Select</option>
                                                            {this.state.categories.map(cat =>
                                                                <option value={cat[0].id}>{cat[0].name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Sub Category:</label>
                                                    <div className="input-group">
                                                        {/* <input className="form-control" name="SubCategory" onChange={this.handleInputChange} placeholder="Enter Sub Category" type="text" />*/}
                                                        <select name="Sub_category_id" id="Sub_category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                            <option value="">Select</option>
                                                            {this.state.subcategories.map(subcat =>
                                                                <option value={subcat.sub_category_id}>{subcat.sub_category_name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">From Date:</label>
                                                    <div className="input-group">
                                                          <input className="form-control datapicker" id="FromDate" onChange={this.handleInputChange} placeholder="Enter From Date" type="text" />
                                                        {/*<DatePicker*/}
                                                        {/*    selected={this.state.startDate}*/}
                                                        {/*    onChange={this.handleChange}*/}
                                                        {/*    showTimeSelect*/}
                                                        {/*    timeFormat="HH:mm"*/}
                                                        {/*    timeIntervals={20}*/}
                                                        {/*    timeCaption="time"*/}
                                                        {/*    dateFormat="MMMM d, yyyy h:mm aa"*/}
                                                        {/*/>*/}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">To Date:</label>
                                                    <div className="input-group">
                                                        <input className="form-control" id="ToDate" onChange={this.handleInputChange} placeholder="Enter To Date" type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="text-left col-12 mt-3">
                                            <button type="submit" className="btn btn-rounded btn-primary">Search</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 tabCustom mb-5 mt-4">
                    <div className="container  px-0 tab-pane active">
                        <div className="card custom-card">
                            <div className="card-body">
                                {contents}
                            </div>
                        </div></div>
                </div>
            </div>
        )
    }
    async populateUsers() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/userapi', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        console.log(data.data);
        this.setState({ users: data.data, loading: false });
    }
    async populateCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ categories: data.data, loading: false });
        }
    }
}

export default Clients;

