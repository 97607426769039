import React, { Component, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";


const SwitchToggle = props => {
    const [checked, setChecked] = useState(props.editRec.is_active == 1 ? true : false);
    const handleChange = (nextChecked) => {
        console.log(props);
        setChecked(nextChecked);
        try {
            fetch('/userapi/updatestatus', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    id: props.editRec.id,
                    status: nextChecked
                }),
            }).then((response) => {
                return response.json();
            })
                .then((data) => {
                    props.callbackFn(data);
                });
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <div className="example">
            <label>
                <Switch
                    onChange={handleChange}
                    checked={checked}
                    className={"react-switch"}
                />
            </label>
            <span>{checked}</span>
        </div>
    );
};

export class DepositRequests extends Component {
    static displayName = DepositRequests.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { users: [], loading: true, selectedrec: "", usersinactive: [], loading2: true };

    }

    componentDidMount() {
        this.populateDepositRequests();
    }

    renderDepositRequestsTable(users, refreshData) {
        return (
            <Table responsive pagination={true} data-show-header="true" data-pagination="true"
                data-id-field="name"
                data-page-list="[5, 10, 25, 50, 100, ALL]"
                data-page-size="10" className="border table-bordered">
                <thead className="bg-light-gradient">
                    <tr>
                        <th className="fontSmall fontBold">Name</th>
                        <th className="fontSmall fontBold">Phone</th>
                        <th className="fontSmall fontBold">Email</th>
                        <th className="fontSmall fontBold">Status</th>
                        <th className="fontSmall fontBold">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map(user =>
                        <tr key={user.id}>
                            <td>
                                {user.name}
                            </td>
                            <td>
                                {user.mobile_number}
                            </td>
                            <td>
                                {user.email}
                            </td>
                            <td className="fontBold">
                                {user.is_active == 1 ? 'Active' : 'Inactive'}
                            </td>
                            <td>
                                <SwitchToggle callbackFn={refreshData} editRec={user} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populateDepositRequests();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderDepositRequestsTable(this.state.users, this.refreshData);
        return (
            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/*    <h4 className="my-3">DepositRequests</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Deposit Requests</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Deposits</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Deposit Requests</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="container px-0 tab-pane active">
                    <div className="card custom-card">
                        <div className="card-body">
                            {contents}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    async populateDepositRequests() {
        const response = await fetch('/userapi');
        const data = await response.json();
        console.log(data.data);
        this.setState({ users: data.data, loading: false });
    }
}

export default DepositRequests;

