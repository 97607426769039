import React, { Component, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import PropTypes from 'prop-types';
import { Login } from '../Login'
import { Register } from '../Register'
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/free-regular-svg-icons'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import '../root/css/main.css';
import '../root/css/style.css';
import '../root/css/color1.css';
import '../root/dashboard/vendors/feather/feather.css';
import '../root/dashboard/vendors/mdi/css/materialdesignicons.min.css';
import '../root/dashboard/vendors/ti-icons/css/themify-icons.css';
import '../root/dashboard/vendors/typicons/typicons.css';
import '../root/dashboard/vendors/simple-line-icons/css/simple-line-icons.css';
import '../root/dashboard/vendors/css/vendor.bundle.base.css';
import '../root/dashboard/js/select.dataTables.min.css';
import '../root/dashboard/css/vertical-layout-light/style.css';
import '../root/dashboard/css/style.css';

class GDPBlankLayout extends React.Component {
    constructor(props) {
        super(props);
        console.log('c');
    }
    componentDidMount() {
        console.log('s');
        $('body').attr('class', 'page login-img');
    }
    componentDidUpdate(prevProps) {
        console.log('u');
        if (this.props.location !== prevProps.location) {
            $('body').attr('class', 'page login-img');
        }
    }
    render() {
        console.log('r');
        const { match } = this.props
        return (
            <div>
                <Switch>
                    <Route
                        exact path={`${match.path}`}
                        render={(props) => <Login {...props} />} />
                    <Route exact path={`${match.path}/Login`} component={Login} />
                    <Route exact path={`${match.path}/Register`} component={Register} />
                </Switch>
            </div>
        )
    }
    
}

GDPBlankLayout.propTypes = {
    match: PropTypes.any.isRequired
}

export default GDPBlankLayout