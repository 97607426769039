import React, { Component, useState } from 'react';
import { ReactSession } from 'react-client-session';
import Pagination from 'react-bootstrap/Pagination';
import PageItem from 'react-bootstrap/PageItem';
import Image from 'react-bootstrap/Image';
import Table from 'react-bootstrap/Table';
import Switch from "react-switch";
import $ from 'jquery';

export class PurchaseCategories extends Component {
    static displayName = PurchaseCategories.name;

    state = {
        modal: false
    }
    state = {
        enabled: false
    }


    selectModal = (info) => {
        this.setState({ modal: !this.state.modal, selectedrec: info });
    }

    viewModal = (info) => {
        this.setState({ enabled: !this.state.enabled })
    }

    constructor(props) {
        super(props);
        this.state = { assignedprices: [], categories: [], subcategories: [], loading: true, selectedrec: "", Category_id: "", Sub_category_id: "", Message: "" };
        this.handleInputChange = this.handleInputChange.bind(this);
    }
    handleInputChange(event) {
        const target = event.target;
        var value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
        if (name == 'Category_id') {
            this.Category_id = value;
            console.log(this.state.categories);
            let subcategories = this.state.categories.filter(
                (cats) => { if (cats[0].id == value) return cats; }
            );
            console.log(value);
            console.log(subcategories);
            this.setState({
                subcategories: subcategories[0],
            });
        }
    }
    componentDidMount() {
        this.populateCategories();
        this.populatePurchaseCategories();
    }
    buyNow(x) {
        $(".load").show();
        var obj = { "Id": "" + x.id + "" };
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/category/buynow', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                // data:  person,
                body: JSON.stringify(obj),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    debugger;
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.setState({ Message: jsonData.message });
                        Array.from(document.querySelectorAll("input")).forEach(
                            input => (input.value = "")
                        );
                        setTimeout(() => {
                            this.props.history.push("/user/purchasehistory");
                        }, 1000);
                        $(".load").hide();
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                        $(".load").hide();
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        try {
            fetch('/category/assignpricesearch', {
                headers: {
                    'Authorization': 'Basic ' + ReactSession.get("token"),
                    'Content-Type': 'application/json',
                },
                method: "POST",
                body: JSON.stringify({
                    Category_id: this.state.Category_id,
                    Sub_category_id: this.state.Sub_category_id,
                }),
            }).then((response) => {
                return response.json();
            })
                .then((jsonData) => {
                    console.log(jsonData);
                    if (jsonData.isSuccess) {
                        this.setState({ assignedprices: jsonData.data, loading: false });
                    }
                    else {
                        this.setState({ Message: jsonData.message });
                    }
                });
        } catch (err) {
            console.log(err);
        }
    }
    renderPurchaseCategoriesTable(assignedprices, refreshData) {
        return (
            
                assignedprices.map(user =>
                    <div className="col-lg-4 mb-4" key={user.id}>
                        <div className="card custom-card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <div className="text-center">
                                            <p className="text-primary">   {user.category_name}</p>
                                            <span className="h6 mb-4 d-block">{user.sub_category_name}</span>
                                            {/*<h3 className="h1">Total Leads: <span className="text-success"> 7.500</span></h3>*/}
                                            <h6 className="text-muted">Price</h6>
                                            <h2 className="font-weight-bold h3 text-danger">GP  {user.price}</h2>
                                            <h4 className="mb-1 text-warning"><span className="text-waning">{user.quantity}</span> / Qty</h4>
                                            <p>&nbsp;</p>
                                            <button type="button" onClick={() => { this.buyNow(user) }} className="btn btn-success btn-radius btn-block btn-lg mt-3 mx-auto">Buy Now</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            )
        );
    }

    isExpandableRow(row) {
        return true;
    }
    refreshData = (data) => {
        this.populatePurchaseCategories();
    };
    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderPurchaseCategoriesTable(this.state.assignedprices, this.refreshData);

        return (
            

            <div className="row m-0 px-3 mt-3">
                <div className="col-xl-12">
                    {/*h4 className="my-3">Assigned Prices</h4>*/}
                    <div className="page-header">
                        <div>
                            <h1 className="page-title">Purchase Categories Data</h1>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Data</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Purchase Data</li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12">
                    <div className="row row-sm">
                        <div className="col-lg-12">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="row">
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Category:</label>
                                                    <div className="input-group">
                                                        <select name="Category_id" id="Category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                            <option value="">Select</option>
                                                            {this.state.categories.map(cat =>
                                                                <option value={cat[0].id}>{cat[0].name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <label className="form-label">Sub Category:</label>
                                                    <div className="input-group">
                                                        <select name="Sub_category_id" id="Sub_category_id" onChange={this.handleInputChange} className="required-entry form-control">
                                                            <option value="">Select</option>
                                                            {this.state.subcategories.map(subcat =>
                                                                <option value={subcat.sub_category_id}>{subcat.sub_category_name}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-left col-12">
                                            <button type="submit" className="btn btn-rounded btn-warning">Search</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-12 tabCustom mb-5 mt-5">
                    <div className="load text-center" style={{'display':'none'} }>
                        <img src="/images/spinner.gif" alt="loading icon" width="25%" />
                        <p className="text-center">Please sit and relax as we  are downloading<br /> data for you.  Thanks</p>
                    </div>
                    <div className={this.state.Message ? "alert alert-success alert-dismissible text-center numbers-sheet" : "alert alert-success alert-dismissible text-center numbers-sheet d-none"} style={{ marginTop: '18px' }}>
                        <strong>
                            {this.state.Message}
                        </strong>
                    </div>
                    {/*<div className="alert alert-success alert-dismissible text-center numbers-sheet d-none">*/}
                    {/*    {this.state.Message}*/}
                    {/*    </div>*/}
                    <div className="container px-0 tab-pane active">
                        <div className="row row-sm">
                            {contents}
                        </div>
                    </div>
                </div>
            </div>
             
        )
    }
    async populatePurchaseCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/userapi', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        const data = await response.json();
        console.log(data.data);
        this.setState({ users: data.data, loading: false });
    }
    async populateCategories() {
        ReactSession.setStoreType("localStorage");
        var token = ReactSession.get("token");
        const response = await fetch('/category', {
            method: 'get',
            headers: {
                'Authorization': 'Basic ' + token,
                'Content-Type': 'application/json',
            }
        });
        if (response.status != '200') {
            this.props.history.push("/");
        }
        else {
            const data = await response.json();
            console.log(data.data);
            this.setState({ categories: data.data, loading: false });
        }
    }
}

export default PurchaseCategories;

